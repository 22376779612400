<template>
  <div>
    <el-form ref="form" :model="form" class="new-goods-form" :rules="rules" label-width="140px" v-loading="loading">
      <el-card header="基础信息配置" shadow="naver">
        <el-form-item label="关联商品限购" prop="rel_limit_id">
          <el-select v-model="form.rel_limit_id" :disabled="isDetail || isEdit" placeholder="请选择关联商品限购" clearable @change="handleGoodsLimitChange" :style="style">
            <el-option
              v-for="item in goodLimtList"
              :key="item.limit_id"
              :label="item.limit_name"
              :value="item.limit_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称" prop="activity_name" :disabled="!!activity_id">
          <el-input v-model="form.activity_name" :disabled="isDetail || isEdit" :maxlength="30" placeholder="最多30个字" clearable :style="style" />
        </el-form-item>
        <el-form-item label="活动时间" required>
          <el-date-picker
            v-model="activity_date"
            type="datetimerange"
            :disabled="isDetail || isEdit"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            @change="onActivityChange"
          />
        </el-form-item>
        <el-form-item label="发货时间文案" prop="delivery_desc" placeholder="请输入发货时间文案">
          <el-input v-model="form.delivery_desc" :disabled="isDetail || isEdit" :maxlength="30" placeholder="最多30个字" clearable :style="style" />
        </el-form-item>
        <el-form-item label="支持仅预热" required>
          <el-switch
            v-model="form.support_only_preheat"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            :disabled="isDetail || isEdit"
            @change="onSupportOnlyPreheatChange"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否期货预售" required>
          <el-switch
            v-model="form.call_delivery"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            :disabled="isDetail || isEdit"
            @change="onCallDeliveryChange"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="图形验证码" required>
          <el-switch
            v-model="form.check_robot"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            :disabled="isDetail || isEdit"
          >
          </el-switch>
        </el-form-item>
      </el-card>

      <el-card header="活动阶段配置" shadow="naver" :class="`setting-card ${form.support_only_preheat == 1 ? 'select-onlypreheat' : ''}`">
        <el-col :span="4" class="setting-position" v-if="form.support_only_preheat == 1">
          <div class="setting-title">仅预热</div>
          <el-form-item label="预热开始时间" prop="onlypreheat_start_time">
            <el-date-picker
              v-model="form.onlypreheat_start_time"
              type="datetime"
              placeholder="预热开始时间"
              value-format="timestamp"
              disabled
            />
          </el-form-item>
          <el-form-item label="腰条文案" prop="onlypreheat_text">
            <el-input v-model="form.onlypreheat_text" :disabled="isDetail || isEdit" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="商详页CTA" prop="onlypreheat_cta">
            <el-input v-model="form.onlypreheat_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
          <el-form-item label="规格页CTA" prop="onlypreheat_spec_cta">
            <el-input v-model="form.onlypreheat_spec_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="4" class="setting-position">
          <div class="setting-title">预热期</div>
          <el-form-item label="预热开始时间" prop="preheat_start_time">
            <el-date-picker
              v-model="form.preheat_start_time"
              type="datetime"
              placeholder="预热开始时间"
              value-format="timestamp"
              disabled
            />
          </el-form-item>
          <el-form-item label="腰条文案" prop="preheat_text">
            <el-input v-model="form.preheat_text" :disabled="isDetail || isEdit" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="商详页CTA" prop="preheat_cta">
            <el-input v-model="form.preheat_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
          <el-form-item label="规格页CTA" prop="preheat_spec_cta">
            <el-input v-model="form.preheat_spec_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="4" class="setting-position">
          <div class="setting-title">预售期</div>
          <el-form-item label="预售开始时间" prop="presale_start_time">
            <el-date-picker
              v-model="form.presale_start_time"
              type="datetime"
              placeholder="预售开始时间"
              value-format="timestamp"
              :disabled="isDetail || presaleStartTime"
            />
          </el-form-item>
          <el-form-item label="腰条文案" prop="presale_text">
            <el-input v-model="form.presale_text" :disabled="isDetail || isEdit" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="商详页CTA" prop="presale_cta">
            <el-input v-model="form.presale_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
          <el-form-item label="规格页CTA" prop="presale_spec_cta">
            <el-input v-model="form.presale_spec_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="4" class="setting-position">
          <div class="setting-title">禁售期</div>
          <el-form-item label="禁售开始时间" prop="forbidsale_start_time">
            <el-date-picker
              v-model="form.forbidsale_start_time"
              type="datetime"
              placeholder="禁售开始时间"
              value-format="timestamp"
              :disabled="isDetail || forbidsaleStartTime"
            />
          </el-form-item>
          <el-form-item label="腰条文案" prop="forbidsale_text">
            <el-input v-model="form.forbidsale_text" :disabled="isDetail || isEdit" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="商详页CTA" prop="forbidsale_cta">
            <el-input v-model="form.forbidsale_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
          <el-form-item label="规格页CTA" prop="forbidsale_spec_cta">
            <el-input v-model="form.forbidsale_spec_cta" :disabled="isDetail || isEdit" placeholder="请输入内容" maxlength="12" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="4" class="setting-position">
          <div class="setting-title">开售期</div>
          <el-form-item label="开售时间" prop="sale_start_time">
            <el-date-picker
              v-model="form.sale_start_time"
              type="datetime"
              placeholder="开售开始时间"
              value-format="timestamp"
              :disabled="isDetail || saleStartTime"
            />
          </el-form-item>
        </el-col>
      </el-card>

      <el-card header="适用商品" shadow="naver">
        <el-form-item label="适用商品">
          <el-radio-group v-model="form.use_bound">
            <el-radio label="goods">指定商品适用</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.use_bound == 'goods'" style="position: relative">
          <SkuSelector  @change="getItems" :data="relItems" :canEdit="!isDetail" :selectSku="!isDetail" :showFooterBtn="!isDetail" />
        </div>
      </el-card>
    </el-form>
    <div class="content-center" style="margin:20px 0">
      <el-button type="primary" v-if="!isDetail" @click="submitActivityAction('form')">保存</el-button>
      <el-button @click.native="handleCancel">返回</el-button>
    </div>
  </div>
</template>

<script>
  import SkuSelector from '@/components/function/skuSelector'
  import {addPresale, getPresaleInfo, editPresale, getLimitPromotions, getLimitPromotionsInfo} from '@/api/promotions'
  import moment from 'moment'

  export default {
    components: {
      SkuSelector
    },
    data() {
      const _this= this
      return {
        loading: false,
        relItems: [],
        activity_date: '',
        style: 'width: 400px',
        rules: {
          activity_name: [
            {required: true, message: '请输入活动名称', trigger: 'blur' }
          ],
          delivery_desc: [
            {required: true, message: '请输入发货时间文案', trigger: 'blur' }
          ],
          onlypreheat_start_time: [
            {required: true, message: '请选择预热开始时间', trigger: 'blur' }
          ],
          onlypreheat_text: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          onlypreheat_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          onlypreheat_spec_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          preheat_start_time: [
            {required: true, message: '请选择预热开始时间', trigger: 'blur' }
          ],
          preheat_text: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          preheat_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          preheat_spec_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          presale_start_time: [
            {required: true, message: '请选择预售开始时间', trigger: 'blur' }
          ],
          presale_text: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          presale_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          presale_spec_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          forbidsale_start_time: [
            {required: true, message: '请选择禁售开始时间', trigger: 'blur' }
          ],
          forbidsale_text: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          forbidsale_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          forbidsale_spec_cta: [
            {required: true, message: '请输入内容', trigger: 'blur' }
          ],
          sale_start_time: [
            {required: true, message: '请选择开售时间', trigger: 'blur' }
          ],
        },
        form: {
          activity_name: '', // 活动名称
          start_time: 0, // 活动开始时间
          end_time: 0, // 活动结束时间
          delivery_desc: '', // 发货时间文案
          support_only_preheat: 0, // 是否支持仅预热，0关，1开
          call_delivery: 0, // 是否期货预售，0关，1开
          check_robot: 0, // 是否开启图形验证码，0关，1开
          // 仅预热
          onlypreheat_start_time: '', // 仅预热开始时间
          onlypreheat_text: '', // 仅预热腰条文案
          onlypreheat_cta: '', // 仅预热商详页CTA
          onlypreheat_spec_cta: '', // 仅预热规格页CTA
          // 预热
          preheat_start_time: '', // 预热开始时间，时间戳
          preheat_text: '', // 预热腰条文案
          preheat_cta: '', // 预热商详页CTA
          preheat_spec_cta: '', // 预热规格页CTA
          // 预售
          presale_start_time: '', // 预售开始时间，时间戳
          presale_text: '', // 预售腰条文案
          presale_cta: '', // 预售商详页CTA
          presale_spec_cta: '', // 预售规格页CTA
          // 禁售
          forbidsale_start_time: '', // 禁售开始时间
          forbidsale_text: '', // 禁售腰条文案
          forbidsale_cta: '', // 禁售商详页CTA
          forbidsale_spec_cta: '', // 禁售规格页CTA
          // 开售
          sale_start_time: '', // 开售开始时间
          item_ids: [],
          use_bound: 'goods',
          rel_limit_id: ''
        },
        activity_id: 0,
        goodLimtList: [],
        isDetail: false, // 活动是否仅支持查看
        isEdit: false, // 活动是否可编辑 进行中状态不可编辑
        // onlypreheatStartTime: false, // 仅预热开始时间是否可编辑
        // preheatStartTime: false, // 预热开始时间是否可编辑
        presaleStartTime: false, // 预售开始时间是否可编辑
        forbidsaleStartTime: false, // 禁售开始时间是否可编辑
        saleStartTime: false, // 开售开始时间是否可编辑
      }
    },
    methods: {
      onSupportOnlyPreheatChange (value) {
        if (value) {
          this.form.call_delivery = 0
        }
      },
      onCallDeliveryChange (value) {
        if (value) {
          this.form.support_only_preheat = 0
        }
      },
      submitActivityAction(formName) {
        console.log(this.form, 'form')
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const that = this
            let formData = JSON.parse(JSON.stringify(this.form))
            if (this.activity_date.length > 0) {
              formData.start_time = this.activity_date[0] / 1000
              formData.end_time = this.activity_date[1] / 1000
            } else {
              this.$message.error('请选择活动时间！')
            }
            if (this.form.item_ids.length > 0) {
              formData['item_ids'] = this.form.item_ids.join(',')
            } else {
              this.$message.error('请选择商品！')
            }
            if (formData.presale_start_time <= formData.preheat_start_time) {
              this.$message.error('预售开始时间不能早于预热开始时间！')
              return false
            }
            if (formData.forbidsale_start_time <= formData.presale_start_time) {
              this.$message.error('禁售开始时间不能早于预售开始时间！')
              return false
            }
            if (formData.sale_start_time <= formData.forbidsale_start_time) {
              this.$message.error('开售开始时间不能早于禁售开始时间！')
              return false
            }
            if (formData.sale_start_time > this.activity_date[1]) {
              this.$message.error('开售开始时间不能晚于活动结束时间！')
              return false
            }
            formData.onlypreheat_start_time = formData.onlypreheat_start_time / 1000
            formData.preheat_start_time = formData.preheat_start_time / 1000
            formData.presale_start_time = formData.presale_start_time / 1000
            formData.forbidsale_start_time = formData.forbidsale_start_time / 1000
            formData.sale_start_time = formData.sale_start_time / 1000
            formData.rel_limit_id = this.form.rel_limit_id || 0
            if (formData.support_only_preheat == 0) { // 未开启仅预热 不传对应的参数
              delete formData.onlypreheat_start_time
              delete formData.onlypreheat_text
              delete formData.onlypreheat_cta
              delete formData.onlypreheat_spec_cta
            }
            formData['activity_id'] = this.activity_id
            if (this.activity_id) {
              editPresale(formData).then(response => {
                this.loading = false
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 2 * 1000,
                  onClose() {
                    that.$router.go(-1)
                  }
                })
              }).catch(err => {
                this.loading = false
              })
            } else {
              addPresale(formData).then(response => {
                this.loading = false
                this.$message({
                  message: '添加成功',
                  type: 'success',
                  duration: 2 * 1000,
                  onClose() {
                    that.getModal()
                    // that.$router.go(-1)
                  }
                })
              }).catch(err => {
                this.loading = false
              })
            }
          }
        })
      },
      getModal () {
        this.$confirm('是否继续创建商品限购活动?', '', {
          confirmButtonText: '继续',
          cancelButtonText: '否',
          type: 'warning'
        }).then(async () => {
          this.$router.push({ path: this.matchInternalRoute('marketing/goodslimit/goodslimit_editor') })
        }).catch(() => {
          this.$router.go(-1)
        })
      },
      getItems(data) {
        let ids = []
        data.forEach(item => {
          ids.push(item.itemId)
        })
        this.form.item_ids = ids
      },
      getPresaleDetail(id) {
        console.log(id, '---')
        this.loading = true
        getPresaleInfo({ activity_id: id }).then(response => {
          let res = response.data.data
          let data = {
            start_time: res.start_time * 1000,
            end_time: res.end_time * 1000,
            rel_limit_id: res.rel_limit_id != 0 ? res.rel_limit_id : '',
            activity_name: res.activity_name,
            delivery_desc: res.delivery_desc,
            support_only_preheat: res.support_only_preheat,
            call_delivery: res.call_delivery,
            check_robot: res.check_robot,
            // 仅预热
            onlypreheat_start_time: res.onlypreheat_start_time * 1000,
            onlypreheat_text: res.onlypreheat_text,
            onlypreheat_cta: res.onlypreheat_cta,
            onlypreheat_spec_cta: res.onlypreheat_spec_cta,
            // 预热
            preheat_start_time: res.preheat_start_time * 1000,
            preheat_text: res.preheat_text,
            preheat_cta: res.preheat_cta,
            preheat_spec_cta: res.preheat_spec_cta,
            // 预售
            presale_start_time: res.presale_start_time * 1000,
            presale_text: res.presale_text,
            presale_cta: res.presale_cta,
            presale_spec_cta: res.presale_spec_cta,
            // 禁售
            forbidsale_start_time: res.forbidsale_start_time * 1000,
            forbidsale_text: res.forbidsale_text,
            forbidsale_cta: res.forbidsale_cta,
            forbidsale_spec_cta: res.forbidsale_spec_cta,
            // 开售
            sale_start_time: res.sale_start_time * 1000,
          }
          Object.assign(this.form, data)
          const currentTime = new Date().getTime()
          // if (currentTime > data.onlypreheat_start_time) {
          //   this.onlypreheatStartTime = true
          // }
          // if (currentTime > data.preheat_start_time) {
          //   this.preheatStartTime = true
          // }
          // if (currentTime > data.presale_start_time) {
          //   this.presaleStartTime = true
          // }
          // if (currentTime > data.forbidsale_start_time) {
          //   this.forbidsaleStartTime = true
          // }
          // if (currentTime > data.sale_start_time) {
          //   this.saleStartTime = true
          // }
          this.relItems = res.itemTreeLists
          this.activity_date = [res.start_time * 1000, res.end_time * 1000]
          if (res.use_bound == 1) {
            this.form.use_bound = 'goods'
          }
          if (this.$route.query.type == 'edit' && res.status_desc == 'going') {
            this.isEdit = true
          }
          this.loading = false
        }).catch(error => {
          this.loading = false
        })
      },
      handleCancel() {
        this.$router.back(-1)
      },
      getGoodLimtList () {
        getLimitPromotions({ page: 1, page_size: 1000}).then(res => {
          this.goodLimtList = res.data.data.list
        })
      },
      async handleGoodsLimitChange (limit_id) {
        console.log(limit_id, this.goodLimtList)
        const obj = this.goodLimtList.find(item => item.limit_id == limit_id)
        console.log(obj.limit_id)
        await getLimitPromotionsInfo(obj.limit_id).then(res => {
          this.relItems = res.data.data.itemTreeLists
          const start_time = new Date(res.data.data.start_time).getTime()
          const end_time = new Date(res.data.data.end_time).getTime()
          this.activity_date = [start_time, end_time]
          this.form.onlypreheat_start_time = start_time
          this.form.preheat_start_time = start_time
        })
      },
      onActivityChange (time) {
        this.form.preheat_start_time = time[0]
        this.form.onlypreheat_start_time = time[0]
      },
    },
    mounted() {
      this.getGoodLimtList()
      console.log(this.$route)
      if (this.$route.query.type == 'detail') {
        this.isDetail = true
      }
      if (this.$route.query.activity_id) {
        this.activity_id = this.$route.query.activity_id
        this.getPresaleDetail(this.activity_id)
      }
    }
  }
</script>
<style lang="scss">
.new-goods-form {
  .setting-title {
    font-size: 18px;
    font-weight: bold;
    color: #409eff
  }
  .setting-position {
    border-right: 2px solid #eee;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 20px;
    border-radius: 0px;
    .el-input {
      width: 90%;
    }
    .el-form-item__label {
      width: auto !important;
    }
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
  .setting-card {
    .el-col {
      width: 25%;
    }
    &.select-onlypreheat {
      .el-col {
      width: 20%;
    }
    }
  }
}
</style>
