<template>
  <div>
    <el-form ref="form" :model="form" class="box-set" label-width="120px" v-loading="loading" disabled>
      <el-card header="设置规则" shadow="naver">
        <el-form-item label="名称" prop="limit_name" :rules="{required: true, message: '请填写规则名称', trigger: 'blur'}" :disabled="!!this.limit_id">
          <el-col :span="20">
            <el-input v-model="form.limit_name" :maxlength=30 placeholder="最多30个字"></el-input>
          </el-col>
        </el-form-item>
        <!--      <el-form-item label="购买规则">-->
        <!--        <el-input v-model="rule.day" :maxlength=30 class="inline-input" style="width: 100px"></el-input>-->
        <!--        天，购买-->
        <!--        <el-input v-model="rule.limit" :maxlength=30 class="inline-input" style="width: 100px"></el-input>-->
        <!--        件-->
        <!--        <p class="frm-tips">天数设置0视为此次活动有效期内，例如：0天，购买1件是指活动有效期内只能购买一件商品</p>-->
        <!--      </el-form-item>-->
        <el-form-item label="适用会员">
          <el-checkbox-group v-model="validGrade">
            <el-checkbox v-for="grade in memberGrade" :label="grade.grade_id" :key="grade.grade_id">
              {{grade.grade_name}}
            </el-checkbox>
            <el-checkbox v-for="vipdata in vipGrade" :label="vipdata.lv_type" :key="vipdata.lv_type">
              付费{{vipdata.grade_name}}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="有效期">
          <el-col :span="20">
            <el-date-picker
              v-model="activity_date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="生效时间"
              end-placeholder="过期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="手机号限购">
          <el-switch
            v-model="form.mobile_phone_limit"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="限购维度">
          <el-radio-group v-model="limit_num_type" @change="changeLimitNumType" :disabled="!!this.limit_id">
            <el-radio label="sku">SKU</el-radio>
            <el-radio label="spu">SPU</el-radio>
            <el-radio label="skus">组合SKU</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="限购数量" v-if="limit_num_type === 'skus'">
          <el-input v-model="form.limit_num" size="mini" class="input-s"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="手机号限购" v-if="limit_num_type === 'skus' && form.mobile_phone_limit === 1">
            <el-input v-model="form.limit_num_mobile" size="mini" class="input-s"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="防黄牛">
          <el-switch
            v-model="form.verification_mobile_number"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <!-- 预售-->
        <!-- <el-form-item label="是否预售">
          <el-radio-group v-model="form.is_presale" :disabled="this.$route.params.limit_id">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">预售</el-radio>
            <el-radio :label="2">仅预热</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0" label="预热时间">
          <el-date-picker
            v-model="form.preheat_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0 && form.is_presale !== 2" label="预售时间">
          <el-date-picker
            v-model="form.presale_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0 && form.is_presale !== 2" label="开售时间">
          <el-date-picker
            v-model="form.sale_time"
            type="datetime"
            placeholder="开售时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0" label="预热腰条文案">
          <el-input v-model="form.preheat_text" placeholder="请输入内容" class="input-b"></el-input>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0" label="预热CTA">
          <el-input v-model="form.preheat_cta" placeholder="请输入内容" class="input-b" maxlength="12" show-word-limit></el-input>
        </el-form-item>
        <el-form-item v-if="form.is_presale !== 0 && form.is_presale !== 2" label="预售腰条文案">
          <el-input v-model="form.presale_text" placeholder="请输入内容" class="input-b"></el-input>
        </el-form-item> -->
        <!--      <el-form-item v-if="form.is_presale" label="开售腰条文案">-->
        <!--        <el-input v-model="form.sale_text" placeholder="请输入内容"></el-input>-->
        <!--      </el-form-item>-->
        <!-- <el-form-item label="发货时间文案" prop="delivery_desc" placeholder="请输入发货时间文案" v-if="form.is_presale===1">
          <el-col :span="20">
            <el-input v-model="form.delivery_desc" :maxlength=30 placeholder="最多30个字"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="是否期货预售">
          <el-switch
            v-model="form.call_delivery"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item> -->
        <!-- <el-form-item label="防黄牛机制">
          <el-switch
            v-model="form.verification_mobile_number"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item> -->
      </el-card>

      <el-card header="选择限购商品" shadow="naver">
        <el-form-item label="适用商品">
          <el-radio-group v-model="form.use_bound" @change="itemTypeChange">
            <el-radio label="goods">指定商品适用</el-radio>
            <!--          <el-radio label="category">指定分类适用</el-radio>-->
            <!--          <el-radio label="tag">指定商品标签适用</el-radio>-->
            <!--          <el-radio label="brand">指定品牌适用</el-radio>-->
          </el-radio-group>
        </el-form-item>
        <div v-if="!zdItemHidden" style="position: relative">
          <SkuSelector  @change="getItems" :data="relItems" :selectSku="isSelectSku"></SkuSelector>
          <!--        <div style="position: absolute; bottom: 0px; left: 112px">-->
          <!--          <el-upload style="display: inline-block; height: 0" action="" :on-change="uploadHandleChange" :auto-upload="false" :show-file-list="false">-->
          <!--            <el-button type="primary">批量上传</el-button>-->
          <!--          </el-upload>-->
          <!--          <el-button style="margin-left: 10px" type="primary" @click="uploadHandleTemplate()">下载模板</el-button>-->
          <!--        </div>-->
        </div>
        <el-col :xs="12" :sm="12" :md="12">
          <div style="height: 350px" v-if="!categoryHidden">
            <treeselect
              :options="categoryList"
              :show-count="true"
              :multiple="true"
              :disable-branch-nodes="true"
              :clearable="false"
              v-model="form.item_category"
              @select="categorySelect"
              @deselect="categoryDeselect"
            >
            </treeselect>
          </div>
        </el-col>
        <template v-if="!tagHidden">
          <div class="selected-tags view-flex">
            <div class="label">已选中标签：</div>
            <div class="view-flex-item">
              <el-tag :key="index" v-for="(tag, index) in tag.currentTags" closable size="small" :disable-transitions="false" @close="tagRemove(index)">
                {{tag.tag_name}}
              </el-tag>
            </div>
          </div>
          <div>
            <el-tag class="tag-item" :key="index" v-for="(tag, index) in tag.tags" size="medium" color="#ffffff" :disable-transitions="false" @click.native="tagAdd(tag, index)">
              {{tag.tag_name}}
            </el-tag>
          </div>
        </template>
        <template v-if="!brandHidden">
          <div class="selected-tags view-flex">
            <div class="label">已选中品牌：</div>
            <div class="view-flex-item">
              <el-tag :key="index" v-for="(brand, index) in brand.currentBrands" closable size="small" :disable-transitions="false" @close="brandRemove(index)">
                {{brand.attribute_name}}
              </el-tag>
            </div>
          </div>
          <div>
            <el-tag class="tag-item" :key="index" v-for="(brand, index) in brand.brands" size="medium" color="#ffffff" :disable-transitions="false" @click.native="brandAdd(brand, index)">
              {{brand.attribute_name}}
            </el-tag>
          </div>
        </template>
      </el-card>
      <el-card header="设置商品" shadow="naver">
        <el-table v-if="form.items.length>0" :data="form.items" style="line-height: normal">
          <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
          <el-table-column label="名称" prop="item_name"></el-table-column>
          <el-table-column label="规格" prop="item_spec_desc"></el-table-column>
          <el-table-column label="商品编码" prop="item_bn"></el-table-column>
          <el-table-column label="限购数量" width="80" v-if="limit_num_type !== 'skus'">
            <template slot-scope="scope">
              <el-input v-model="scope.row.limit_num" size="mini"
                        onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手机号限购数量" width="80" v-if="limit_num_type !== 'skus' && form.mobile_phone_limit === 1">
            <template slot-scope="scope">
              <el-input v-model="scope.row.limit_num_mobile" size="mini"
                        onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card header="无效商品（已参加其他活动）" shadow="naver">
        <el-table v-if="invalidItemsList.length>0" :data="invalidItemsList" style="line-height: normal">
          <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
          <el-table-column label="名称" prop="itemName"></el-table-column>
          <el-table-column label="规格" prop="item_spec_desc"></el-table-column>
          <!-- <el-table-column label="操作" width="50">
            <template slot-scope="scope">
            <i class="iconfont icon-trash-alt" @click="deleteItemRow(scope.$index, form.items)"></i>
            </template>
          </el-table-column> -->
        </el-table>
      </el-card>
    </el-form>
    <div class="content-center" style="margin:20px 0">
      <!--      v-if="hasSaveButton"-->
      <el-button type="primary"  @click="submitActivityAction()">保存</el-button>
      <el-button @click.native="handleCancel">返回</el-button>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import SkuSelector from '@/components/function/skuSelector'
import {getGradeList} from '../../../../api/membercard'
import {listVipGrade} from '../../../../api/cardticket'
import {createLimitPromotions, getLimitPromotionsInfo, updateLimitPromotions, seckillActivityGetItemsList} from '../../../../api/promotions'
import { getItemsList, getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import {handleUploadFile, exportUploadTemplate} from '../../../../api/common'

export default {
  components: {
    SkuSelector,
    Treeselect
  },
  data() {
    return {
      loading: false,
      relItems: [],
      activity_date: '',
      validGrade: [],
      vipGrade: [],
      memberGrade: [],
      rule: {
        day: 0,
        limit: 1,
      },
      limit_num_type: 'sku',
      isSelectSku: true,
      form: {
        limit_num: 0,
        limit_name: '',
        delivery_desc: '',
        call_delivery: false,
        items: [],
        start_time: 0,
        end_time: 0,
        valid_grade: [],
        use_bound: 'goods',
        item_category: [],
        tag_ids : [],
        brand_ids: [],
        is_presale: 0,
        preheat_time: '',
        presale_time: '',
        sale_time: '',
        preheat_text: '',
        preheat_cta: '',
        presale_text: '',
        sale_text: '',
        verification_mobile_number: false,
        mobile_phone_limit: 0,
        limit_num_mobile: 0
      },
      relMain: [],
      setMainStatus: false,
      mainVisible: false,
      hasSaveButton: true,
      zdItemHidden: false,
      categoryHidden: true,
      categoryList: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: [],
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: [],
        },
        currentBrands: [],
        brands: []
      },
      ItemsList: [],
      invalidItemsList: [],
      params: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        templates_id: '',
        keywords: '',
        category: 0,
        is_warning: false,
        tag_id: '',
        is_gift: 'all',
        type: 0,
        is_sku: 'true',
        item_id: '',
      },
    }
  },
  methods: {
    submitActivityAction() {
      const that = this
      let formData = JSON.parse(JSON.stringify(this.form))
      if (this.activity_date.length > 0) {
        formData.start_time = this.activity_date[0]
        formData.end_time = this.activity_date[1]
      }

      if (this.validGrade.length > 0) {
        formData.valid_grade = this.validGrade
      }
      formData.day = this.rule.day
      formData.limit = this.rule.limit
      formData.item_limits = this.form.items;
      // if (formData.sale_time < formData.presale_time[1]) {
      //   this.$message({
      //     type: 'error',
      //     message: '开售时间不能小于预售结束时间！'
      //   })
      //   return
      // }
      if (this.$route.params.limit_id) {
        updateLimitPromotions(this.$route.params.limit_id, formData).then(response => {
          this.loading = false
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()
      } else {
        createLimitPromotions(formData).then(response => {
          this.loading = false
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()
      }
    },
    getItems(data) {
      this.ItemsList = []
      let items = []
      data.forEach(item => {
        var itemData = {
          'item_id': item.item_id,
          'goods_id': item.goods_id,
          'default_item_id': item.default_item_id,
          'item_name': item.item_name,
          'item_bn': item.item_bn,
          'item_spec_desc': item.item_spec_desc,
          'limit_num_type': this.limit_num_type,
          'limit_num': item.limit_num ? item.limit_num : 0
        }
        if (this.limit_num_type === 'skus') {
            itemData.limit_num_mobile = this.form.limit_num_mobile
          } else {
            itemData.limit_num_mobile = item.limit_num_mobile ? item.limit_num_mobile : 0
          }
        if (this.limit_num_type === 'spu') { // 限购维度为spu时，只存储一条记录
          if (item.is_default == '1') {
            itemData.item_spec_desc = '全规格'
            items.push(itemData)
          }
        } else {
          items.push(itemData)
        }

        this.ItemsList.push(item);
      })
      this.form.items = items
    },
    getLimitPromotionsInfo(id) {
      this.loading = true
      getLimitPromotionsInfo(id).then(response => {
        let res = response.data.data
        let data = {
          limit_name: res.limit_name,
          delivery_desc: res.delivery_desc,
          item_category: res.item_category,
          tag_ids: res.tag_ids,
          tag_list: res.tag_list,
          brand_ids: res.brand_ids,
          brand_list: res.brand_list,
          rel_brand_ids: res.rel_brand_ids,
          rel_category_ids: res.rel_category_ids,
          rel_tag_ids: res.rel_tag_ids,
          is_presale: res.is_presale,
          preheat_text: res.preheat_text,
          presale_text: res.presale_text,
          sale_text: res.sale_text,
          preheat_cta: res.preheat_cta,
          verification_mobile_number: res.verification_mobile_number === 1 ? true : false,
          call_delivery: res.call_delivery === 1 ? true : false,
          mobile_phone_limit: res.mobile_phone_limit,
          limit_num_mobile: res.limit_num_mobile
        }
        data.preheat_time = [res.preheat_start_time, res.preheat_end_time];
        data.presale_time = [res.presale_start_time, res.presale_end_time];
        data.sale_time = res.sale_start_time
        if (response.data.data.itemTreeLists.length>0) { // 仅预热
          data.limit_num = response.data.data.itemTreeLists[0].limit_num
        }
        this.limit_num_type = res.limit_num_type
        Object.assign(this.form, data)
        this.rule = JSON.parse(response.data.data.rule)
        this.relItems = response.data.data.itemTreeLists
        this.validGrade = response.data.data.valid_grade
        this.activity_date = [response.data.data.start_time, response.data.data.end_time]
        this.zdItemHidden = true
        this.categoryHidden = true
        this.tagHidden = true
        this.brandHidden = true
        if (response.data.data.use_bound == 1) {
          this.form.use_bound = 'goods'
          this.zdItemHidden = false
        }

        if (response.data.data.use_bound == 2) {
          this.form.use_bound = 'category'
          this.categoryHidden = false
          this.generateSku()
        }

        if (response.data.data.use_bound == 3) {
          this.form.use_bound = 'tag'
          this.tagHidden = false
          this.tag.currentTags = response.data.data.tag_list || []
          this.showTags()
          this.generateSku()
        }

        if (response.data.data.use_bound == 4) {
          this.form.use_bound = 'brand'
          this.brandHidden = false
          this.brand.currentBrands = response.data.data.brand_list || []
          this.showBrands()
          this.generateSku()
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    handleCancel() {
      this.$router.back(-1)
    },
    getListVipGrade() {
      listVipGrade().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          this.vipGrade = response.data.data
        }
      })
    },
    getGradeList() {
      getGradeList().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          var result = response.data.data
          if (result) {
            this.memberGrade = result
          }
        }
      })
    },
    itemTypeChange: function (val) {
      this.params.main_cat_id = ''
      this.params.tag_id = ''
      this.params.brand_id = ''
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.items = []
      this.invalidItemsList = []
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === "goods") {
        this.zdItemHidden = false
      } else if (val === "category") {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === "tag") {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === "brand") {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    fetchMainCate: function () {
      getCategory({is_main_category: true}).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag: function () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags: function () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
    },
    tagRemove: function (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      let tagItems = []
      let tagInvalidItems = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
        let items = []

        this.ItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) items.push(i)
        })
        tagItems = items

        let invalidItems = []
        this.invalidItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) invalidItems.push(i)
        })
        tagInvalidItems = invalidItems
      })
      this.ItemsList = tagItems
      this.invalidItemsList = tagInvalidItems
      this.getItems(this.ItemsList)
    },
    tagAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
      this.params.tag_id = item.tag_id
      this.getGoodsList()
    },
    getAllTagLists: function () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    // 获取品牌列表
    getBrandList: function (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({attribute_name: item.attribute_name, attribute_id: item.attribute_id})
        }
        this.brand.list = list
      })
    },
    showBrands: function () {
      this.brand.brands = [...this.brand.list]
      this.brand.brands.forEach((item, index) => {
        let isInArr = this.brand.currentBrands.findIndex((n) => n.attribute_id == item.attribute_id)
        if (isInArr != -1) this.brand.brands.splice(index, 1)
      })
    },
    brandAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
      this.params.brand_id = item.attribute_id
      this.getGoodsList()
    },
    brandRemove: function (index) {
      let items = []
      this.ItemsList.forEach(item => {
        if (this.brand.currentBrands[index].attribute_id != item.brand_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (this.brand.currentBrands[index].attribute_id != i.brand_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)

      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    getGoodsList: function () {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.$route.params.limit_id) {
        params.activity_id = this.$route.params.limit_id
      }
      params.seckill_type = this.form.seckill_type
      params.activity_release_time = params.activity_start_time = this.activity_date[0]
      params.activity_end_time = this.activity_date[1]
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }
      seckillActivityGetItemsList(params).then(response => {
        let itemList = response.data.data.list.validItems.concat(this.ItemsList)
        let invalidItemList = response.data.data.list.invalidItems.concat(this.invalidItemsList)
        let newItemList = []
        let invalidItems = []
        let obj = {}
        let obj1 = {}
        itemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj[item.item_id]) {
            newItemList.push(item)
            obj[item.item_id] = true
          }
        })
        invalidItemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj1[item.item_id]) {
            invalidItems.push(item)
            obj1[item.item_id] = true
          }
        })

        this.ItemsList = newItemList
        this.invalidItemsList = invalidItems
        this.getItems(this.ItemsList)
      })
    },
    categorySelect: function (node, instanceId) {
      this.params.main_cat_id = node.category_id
      this.getGoodsList()
    },
    categoryDeselect: function (node, instanceId) {
      let items;
      items = [];
      this.ItemsList.forEach((item, index) => {
        if (node.category_id != item.item_main_cat_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (node.category_id != i.item_main_cat_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)
    },
    generateSku() {
      let noSkuItem;
      noSkuItem = [];
      let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItems))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.params
        param.item_id = noSkuItem
        getItemsList(this.params).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id) {
                  item.spec_items.push(sku)
                }
              })
            }
          })
          goodsList.forEach(item => {
            if (!item.nospec) {
              response = [...response, ...item.spec_items]
            } else {
              response = [...response, item]
            }
          })
          this.ItemsList = response
          this.getItems(response)
        })
      } else {
        goodsList.forEach(item => {
          if (!item.nospec) {
            response = [...response, ...item.spec_items]
          } else {
            response = [...response, item]
          }
        })
        this.ItemsList = response
        this.getItems(response)
      }
    },
    /**
     * 下载模板
     * */
    uploadHandleTemplate() {
      let params = {file_type: 'marketing_goods', file_name: '商品模板'}
      exportUploadTemplate(params).then(response => {
        let {data} = response.data
        if (data.file) {
          var a = document.createElement('a')
          a.href = data.file
          a.download = data.name
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    /**
     * 上传模板
     * */
    uploadHandleChange(file, fileList) {

      let params = {isUploadFile: true, file_type: 'marketing_goods', file: file.raw}
      handleUploadFile(params).then(response => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        let {data} = response.data

        if (data.fail.length > 0) {
          let str = data.fail.map(item => {
            return item.item_bn
          })

          setTimeout(() => {
            this.$message({
              showClose: true,
              message: `以下商品编号不存在：${str}`,
              type: 'error',
              duration: 100000
            })
          }, 1500)
        }
        if (data.succ.length <= 0) return
        this.relItems = data.succ
        let list = []
        data.succ.forEach(item => {
          if (!item.nospec) {
            list.push(Object.assign(item, {spec_items: []}))
          } else {
            list.push(item)
          }
        })
      })
    },

    /**
     * 切换sku/spu
     */
    changeLimitNumType(){
      this.getItems(this.ItemsList)
      if (this.limit_num_type === 'sku' || this.limit_num_type === 'skus') {
        this.isSelectSku = true
      } else {
        this.isSelectSku = false
      }
    }
  },
  mounted() {
    this.getListVipGrade()
    this.getGradeList()
    if (this.$route.query.isshow) {
      this.hasSaveButton = false
    }
    if (this.$route.params.limit_id) {
      this.limit_id = this.$route.params.limit_id
      this.getLimitPromotionsInfo(this.$route.params.limit_id)
    }
    // this.fetchMainCate()
    // this.getAllTagLists()
    // this.getBrandList('', true)
  }
}
</script>
