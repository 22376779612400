<template>
  <div class="setting-view">
    <div class="appmsg_input_area">
      <div id="submitContent"></div>
      <el-form ref="form" :model="form" label-position="left" label-width="100px">
        <div class="content-padded">
          <el-form-item label="请选择类型">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option label="门店服务" value="service">门店服务</el-option>
              <el-option label="门店活动" value="activity">门店活动</el-option>
              <el-option v-if="form.platform !== 'byte_mini'" label="热卖" value="selling">热卖</el-option>
              <el-option label="福利" value="welfare">福利</el-option>
              <el-option label="企业员工购" value="ecPurchase">企业员工购</el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.type === 'ecPurchase'" label="员工购标签" style="">
            <el-input v-model="form.activity_title" placeholder="请输入员工购标签" class="input-b"></el-input>
          </el-form-item>
          <el-form-item label="使用平台">
            <el-select v-model="form.platform" disabled placeholder="使用平台">
              <el-option label="微信小程序" value="wxapp"></el-option>
              <el-option label="抖音小程序" v-if="login_type === 'disti' && admin_type === 'order'"
                value="byte_mini"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling'" label="热卖标签" style="">
            <el-input v-model="form.activity_title" placeholder="请输入热卖标签" class="input-b"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling'" label="热卖描述" style="">
            <el-input v-model="form.activity_desc" placeholder="请输入热卖描述" class="input-b"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling'" label="热卖icon" required>

            <el-row>
              <el-col :span="5">
                <div>
                  <img v-if="form.activity_cover" :src="wximageurl + form.activity_cover" class="icon-uploader"
                    @click="handleImgChange('activity_cover')" />
                  <div v-else class="icon-uploader" @click="handleImgChange('activity_cover')">
                    <i class="iconfont icon-camera"></i>
                    <i class="icon-sm-text">上传未选中icon</i>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div>
                  <img v-if="form.activity_active_cover" :src="wximageurl + form.activity_active_cover"
                    class="icon-uploader" @click="handleImgChange('activity_active_cover')" />
                  <div v-else class="icon-uploader" @click="handleImgChange('activity_active_cover')">
                    <i class="iconfont icon-camera"></i>
                    <i class="icon-sm-text">上传已选中icon</i>
                  </div>
                </div>
              </el-col>
            </el-row>

          </el-form-item>
          <el-form-item label="资源类型" v-if="form.type === 'selling'">
            <el-radio-group v-model="form.media_type" @change="handleChangeType">
              <el-radio :label="1">视频</el-radio>
              <el-radio :label="2">图片</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="图片" v-if="form.type === 'selling' && form.media_type == 2">
            <div class="form-type">
              <SpImagePicker v-model="form.video_cover" size="small" />
              <i class="el-icon-delete-solid" @click="delImg" v-if="form.video_cover"></i>
            </div>
          </el-form-item>
          <el-form-item label="" v-if="form.type === 'selling' && form.media_type == 2">
            <div class="btn-content">
              <div class="btn-linkpath" @click="onPickerPath">
                {{ form.page_url && form.page_url.id ? form.page_url.title : '选择自定义页面' }}
              </div>
              <el-button type="text" @click='hendleClear'>清空</el-button>
            </div>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling' && form.media_type == 1" label="视频封面">
            <img v-if="form.video_cover" :src="wximageurl + form.video_cover" class="video-uploader"
              @click="handleImgChange('video_cover')" />
            <div v-else class="icon-uploader" @click="handleImgChange('video_cover')">
              <i class="iconfont icon-camera"></i>
              <i class="icon-sm-text">上传视频封面</i>
            </div>
            <div class="img-del" v-if="form.video_cover">
              <i class="el-icon-delete-solid" @click="delImg"></i>
            </div>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling' && form.media_type == 1" label="自动播放">
            <el-switch v-model="form.auto_play" active-color="#27cc6a" inactive-color="#efefef">
            </el-switch>
          </el-form-item>
          <el-form-item v-if="form.type === 'selling' && form.media_type == 1" label="视频">
            <div v-if="form.video_url">
              <div class="flex-video">
                <video-player class="vjs-custom-skin" :options="{
                  aspectRatio: '16:9',
                  sources: [{
                    // mp4
                    type: 'video/mp4',
                    src: form.video_url
                  }],
                  notSupportedMessage: '无可播放媒体资源',
                  controlBar: false
                }">
                </video-player>
                <div class="video-del">
                  <i class="el-icon-delete-solid" @click="delVideo"></i>
                </div>
              </div>
            </div>
            <div v-else>
              <Videoselect @change="handleVideoChange" :multiple="false"></Videoselect>
            </div>
          </el-form-item>
          <el-form-item v-if="form.type === 'welfare'" label="福利名称" style="height: 80px">
            <el-input v-model="form.activity_title" placeholder="请输入福利名称"></el-input>
          </el-form-item>
          <el-form-item v-if="(form.type !== 'selling') && (form.type !== 'welfare') && (form.type !== 'ecPurchase')"
            label="活动标题" style="height: 80px">
            <el-input v-model="form.activity_title" placeholder="请输入活动标题"></el-input>
          </el-form-item>
          <!--<el-form-item v-if="(form.type !== 'selling') && (form.type !== 'welfare')" label="活动副标题" style="height: 80px">
            <el-input v-model="form.activity_subtitle" placeholder="请输入优惠活动副标题"></el-input>
          </el-form-item>-->
          <el-form-item v-if="form.type === 'welfare'" label="店铺选择">
            <el-tag :key="item.distributor_id" class="new-tag" v-for="(item, index) in relDistributors" closable
              :disable-transitions="false" @close="handleClose(index)"> {{ item.name }}
            </el-tag>
            <el-button size="medium" class="button-new-tag" @click="addDistributoreAction">+ 点击搜索店铺 </el-button>
          </el-form-item>
          <el-form-item v-if="(form.type !== 'selling') && (form.type !== 'ecPurchase')" label="页面选择">
            <div class="goods-select" @click="handleGoodsChange">
              <div class="link-content" v-if="form.page_url && form.page_url.id">
                <template v-if="form.page_url.linkPage === 'goods'">商品：</template>
                <template v-if="form.page_url.linkPage === 'category'">分类：</template>
                <template v-if="form.page_url.linkPage === 'article'">文章：</template>
                <template v-if="form.page_url.linkPage === 'planting'">软文：</template>
                <template v-if="form.page_url.linkPage === 'link'">页面：</template>
                <template v-if="form.page_url.linkPage === 'marketing'">营销：</template>
                {{ form.page_url.title }}
              </div>
              <div v-else class="content-center"><i class="iconfont icon-link" @click="handleGoodsChange"></i>设置路径</div>
            </div>
          </el-form-item>

          <el-form-item v-if="form.type === 'selling' || form.type === 'ecPurchase'" label="商品选择">
            <el-button type="primary" class="el-icon-plus" @click="chooseItems">选择商品</el-button>
            <el-table v-if="form.items && form.items.length > 0" :data="form.items" style="line-height: normal">
              <el-table-column label="ID" prop="goods_id" width="60"></el-table-column>
              <el-table-column label="名称" prop="item_name"></el-table-column>
              <el-table-column label="商品编码" prop="item_bn"></el-table-column>
              <el-table-column label="价格" width="100">
                <template slot-scope="scope">
                  <i slot="prefix" class="el-input__icon">{{ cursymbol }}</i>
                  <i>{{ scope.row.activity_price }}</i>
                </template>
              </el-table-column>
              <el-table-column label="热卖描述" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.selling_text" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="排序" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sort" size="mini"></el-input>
                </template>
              </el-table-column>
            </el-table>

            <!--<SkuSelector @change="getItems" :data="relItems"></SkuSelector>-->
            <!--<el-button size="medium" class="button-new-tag" @click="addDistributoreAction">+ 点击选择商品 </el-button>-->
          </el-form-item>
         <el-form-item label="机型对比" v-if="form.type === 'selling'" class="recommend-selling">
             <el-select v-model="form.device_compare" placeholder="请选择" clearable @clear="handleChangeCompare">
              <el-option :key="item.value" :label="item.label" :value="item.label" v-for="item in deviceOptions"/>
            </el-select>
            <SpImagePicker v-if="form.device_compare" v-model="form.device_compare_img" size="small" class="video-link" text=""/>
          </el-form-item>
          <el-form-item label="机型推荐" v-if="form.type === 'selling'" class="recommend-selling">
            <el-select v-model="form.device_recommend" placeholder="请选择" clearable @clear="handleChangeRecommend">
              <el-option :key="item.value" :label="item.label" :value="item.label" v-for="item in deviceOptions"/>
            </el-select>
            <SpImagePicker v-if="form.device_recommend" v-model="form.device_recommend_img" size="small" class="video-link" text=""/>
          </el-form-item>
          <!--<el-form-item v-if="(form.type !== 'selling') && (form.type !== 'welfare')" label="*请选择截止时间">
	          <el-date-picker v-model="created" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" @change="dateChange">
            </el-date-picker>
		      </el-form-item>-->

        </div>
        <!--<div v-if="form.type === 'welfare'" class="content-padded appmsg_edit_highlight_area" style="height: 420px">
          <el-form-item label="*请选择截止时间" >
	          <el-date-picker v-model="created" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" @change="dateChange">
            </el-date-picker>
		      </el-form-item>
          <el-card header="设置商品" shadow="naver" v-if="form.type === 'welfare'">
            <el-table v-if="form.items.length>0" :data="form.items" style="line-height: normal">
              <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
              <el-table-column label="名称" prop="item_title"></el-table-column>
              <el-table-column label="热卖描述" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.limit_num" size="mini"
                            ></el-input>
                </template>
              </el-table-column>
              &lt;!&ndash; <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i class="iconfont icon-trash-alt" @click="deleteItemRow(scope.$index, form.items)"></i>
                </template>
              </el-table-column> &ndash;&gt;
            </el-table>
          </el-card>
        </div>-->
        <div v-if="(form.type !== 'selling') && (form.type !== 'ecPurchase')"
          class="content-padded appmsg_edit_highlight_area" style="height: 420px">
          <div class="header_tips">封面小图片 <span class="form-text-tip">建议尺寸：200像素 * 200像素</span></div>
          <el-form-item>
            <div>
              <el-button @click="addThumbPreview">从图片库选择</el-button>
            </div>
            <div class="cover_preview" v-if="form.activity_cover"
              :style="{ backgroundImage: 'url(' + (form.activity_cover ? wximageurl + form.activity_cover : '') + ')' }">
            </div>
          </el-form-item>
        </div>

      </el-form>
      <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" @chooseImg="pickThumb"
        @closeImgDialog="closeThumbDialog"></imgPicker>
      <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg"
        @closeImgDialog="closeImgDialog">
      </imgPicker>
      <imgPicker :dialog-visible="sellingImgsVisible" :sc-status="sellingIsGetImage" @chooseImg="sellingPickImg"
        @closeImgDialog="sellingCloseimgsVisible"></imgPicker>
    </div>
    <div class="setting-footer"><el-button type="primary" @click="onSubmit">保存</el-button></div>

    <GoodsSelect
      :items-visible="itemVisible"
      :get-status="setItemStatus"
      :rel-items-ids="relItemsIds"
      @chooseStore="chooseItemsAction"
      @closeStoreDialog="closeItemDialogAction"
    ></GoodsSelect>
    <DistributorSelect :store-visible="DistributorVisible" :is-valid="isValid" :rel-data-ids="relDistributors" :platform="form.platform"
      :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="closeDialogAction">
    </DistributorSelect>
    <CustomizeSelect :store-visible="CustomizeVisible" :is-valid="isValid1" :rel-data-ids="customizeList"
      :get-status="CustomizeStatus" @chooseStore="CustomizeChooseAction" @closeStoreDialog="closeDialogAction1">
    </CustomizeSelect>
    <linkSetter :links="linksArr" :visible="linksVisible" @setLink="setLink" @closeDialog="closeDialog"
      :show_article="false" :show_planting="false" :show_page="false" :show_marketing="false" :show_store="false">
    </linkSetter>
    <linkSetterDouyin :links="linksArr" :visible="linksDouyinVisible" @setLink="setLink" @closeDialog="closeDialog"
      :show_article="false" :show_planting="false" :show_page="false" :show_marketing="false" :show_store="false">
    </linkSetterDouyin>
  </div>
</template>

<script>
import { getPreferentialInfo, createPreferential, updatePreferential, getCustomizeList } from '@/api/promotions'
import { getDistributorList } from '@/api/marketing'
import imgPicker from '@/components/imageselect'
import GoodsSelect from '@/components/goodsSelect'
import linkSetter from '@/components/template_links' // 添加导航连接
import linkSetterDouyin from '@/components/template_links_douyin'
import DistributorSelect from '@/components/function/distributorSelect'
import CustomizeSelect from '@/components/function/customizeSelect'
import Videoselect from "@/components/videoselect";
import { mapGetters } from "vuex";
import {DEVICEOPTION} from "@/consts"

export default {
  inject: ['refresh'],
  components: {
    DistributorSelect,
    CustomizeSelect,
    imgPicker,
    linkSetter,
    linkSetterDouyin,
    GoodsSelect,
    Videoselect
  },

  data() {
    return {
      deviceOptions:DEVICEOPTION,
      linksArr: [],
      cursymbol: '￥',
      linksVisible: false,
      linksDouyinVisible: false,
      // relItems: [],
      relItemsIds: [],
      setItemStatus: false,
      itemVisible: false,
      imgDialog: false,
      thumbDialog: false,
      isGetImage: false,
      isGetThumb: false,
      isValid: true,
      isValid1: true,
      DistributorStatus: false,
      DistributorVisible: false,
      CustomizeStatus: false,
      CustomizeVisible: false,
      relDistributors: [],
      customizeList: [],
      relItems: [],
      addedItems: [],
      created: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      form: {
        id: '',
        distributor_ids: [],
        customize: '',
        activity_title: '',
        activity_subtitle: '',
        activity_desc: '',
        activity_cover: '',
        activity_active_cover: '',
        type: '',
        platform: 'wxapp',
        media_type: 1,
        imgUrl: '',

        page_url: {},
        start_time: '',
        end_time: '',
        items: [],
        video_cover: '',
        video_url: '',
        auto_play: false,
        device_compare:'',
        device_recommend:'',
        device_recommend_img:'',
        device_compare_img:''
      },
      // 图片选择器
      sellingIsGetImage: false,
      sellingImgsVisible: false,
      sellingType: ''
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
      'admin_type'
    ])
  },
  methods: {
    handleChangeCompare(){
      this.$set(this.form,'device_compare_img','')
    },
    handleChangeRecommend(){
      this.$set(this.form,'device_recommend_img','')
    },
    delVideo() {
      this.form.video_url = ''
    },
    delImg() {
      this.$set(this.form, 'video_cover', '')
    },
    handleVideoChange(data) {
      if (data && data.url !== '') {
        this.form.video_url = data.url
      }

    },
    sellingCloseimgsVisible() {
      this.sellingImgsVisible = false;
    },
    sellingPickImg(data) {
      if (data && data.url !== '') {
        this.sellingCloseimgsVisible()
        if (this.sellingType === 'activity_cover') {
          this.form.activity_cover = data.url
        } else if (this.sellingType === 'activity_active_cover') {
          this.form.activity_active_cover = data.url
        } else {
          this.form.video_cover = data.url
        }
      }
    },
    handleImgChange(str) {
      this.sellingIsGetImage = true;
      this.sellingImgsVisible = true;
      this.sellingType = str
    },
    // chooseItems() {
    //   this.itemVisible = true
    //   this.setItemStatus = true
    // },
    getFormItemsIndex(itemId) {
      for (const key in this.form.items) {
        if (this.form.items[key].item_id === itemId) {
          return key
        }
      }
      return -1
    },
    async chooseItems() {
      const { data } = await this.$picker.goodsitem({
        data: this.relItemsIds,
        multiple: true
      })
      this.relItemsIds = data
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        let oldIndex = this.getFormItemsIndex(item.item_id || item.itemId)
        // let isInArr = this.form.items.findIndex((n) => n.item_id == item.itemId)
        if (oldIndex === -1) {
          newData = {
            item_id: item.item_id || item.itemId,
            item_name: item.item_name || item.itemName,
            item_bn: item.item_bn,
            activity_store: item.activity_store || item.store,
            activity_price: item.activity_price || item.price / 100,
            sort:0,
            vip_price: 0,
            svip_price: 0,
            points: 0,
            limit_num: 0,
            initial_sales: 10,
            selling_text: item.selling_text || '',
            home_pic: item.home_pic,
            goods_id: item.goods_id,
            brief: item.brief,
            is_ec: item.is_ec,
            ec_market_price: item.ec_market_price
          }
        } else {
          newData = this.form.items[oldIndex]
        }
        arr.push(newData)
      })
      this.form.items = arr
      // this.itemVisible = true
      // this.setItemStatus = true
    },
    chooseItemsAction(data) {
      this.itemVisible = false
      this.relItemsIds = data || []
      console.log(data, 228)
      // if (data === null || data.length <= 0) return
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        let oldIndex = this.getFormItemsIndex(item.itemId)
        // let isInArr = this.form.items.findIndex((n) => n.item_id == item.itemId)
        if (oldIndex === -1) {
          newData = {
            item_id: item.item_id || item.itemId,
            item_name: item.item_name || item.itemName,
            item_bn: item.item_bn,
            activity_store: item.activity_store || item.store,
            activity_price: item.activity_price || item.price / 100,
            sort: item.sort,
            vip_price: 0,
            svip_price: 0,
            points: 0,
            limit_num: 0,
            initial_sales: 10,
            selling_text: item.selling_text || '',
            home_pic: item.home_pic,
            goods_id: item.goods_id,
            brief: item.brief,
            is_ec: item.is_ec,
            ec_market_price: item.ec_market_price
          }
        } else {
          newData = this.form.items[oldIndex]
        }
        console.log(newData.item_id)
        arr.push(newData)
      })
      this.form.items = arr
      console.log(this.form.items, 277)
    },
    closeItemDialogAction() {
      this.itemVisible = false
      this.setItemStatus = false
    },
    handleGoodsChange() {
      if (this.form.platform === 'byte_mini') {
        this.linksDouyinVisible = true
        return
      }
      this.linksVisible = true
    },
    setLink(data, type) {
      console.log(data, type, 177)
      let obj = Object.assign(data, { 'linkPage': type })
      this.form.page_url = obj
    },
    closeDialog() {
      if (this.form.platform === 'byte_mini') {
        this.linksDouyinVisible = false
        return
      }
      this.linksVisible = false
    },
    dateChange(val) {
      console.log(val)
      if (val && val.length > 0) {
        this.form.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.form.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.form.start_time = ''
        this.form.end_time = ''
      }
    },
    handleClick() {
      if (this.form.type === "service") {
        this.isShow = true;
      } else if (this.form.type === "activity") {
        this.isShow = true;
      } else if (this.form.type === "selling") {
        this.isShow = false;
      }
    },

    getItems(data) {
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        // let isInArr = this.addedItems.findIndex((n) => n.item_id == item.itemId)
        // if (isInArr == -1) {
        newData = {
          item_id: item.itemId,
          item_title: item.itemName,
          activity_store: item.store,
          activity_price: item.price / 100,
          item_spec_desc: item.item_spec_desc,
          sort: item.sort,
          limit_num: "",
          item_type: item.item_type,
          home_pic: item.home_pic,
          goods_id: item.goods_id,
          brief: item.brief
        }
        // } else {
        //   newData = this.addedItems[isInArr]
        // }
        let flag = false;
        if (newData) {
          arr.forEach((k, v) => {
            if (k.item_title == newData.item_title) {
              flag = true;
            };
          })
          if (!flag) {
            arr.push(newData)
          }
        }
      })
      this.form.items = arr
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    handleClose(index) {
      this.relDistributors.splice(index, 1);
      this.form.distributor_ids.splice(index, 1);
    },
    handleClose1(index) {
      this.customizeList.splice(index, 1);
      this.form.customize_id = 0;
    },
    closeDialogAction() {
      this.DistributorStatus = false
      this.DistributorVisible = false
    },
    closeDialogAction1() {
      this.CustomizeStatus = false
      this.CustomizeVisible = false
    },
    addDistributoreAction() {
      this.DistributorVisible = true
      this.DistributorStatus = true
    },
    addCustomizeAction() {
      this.CustomizeStatus = true
      this.CustomizeVisible = true
    },
    CustomizeChooseAction(data) {
      this.CustomizeVisible = false
      this.CustomizeStatus = false
      if (data === null || data.length <= 0) return
      this.customizeList = data
    },

    DistributorChooseAction(data) {
      this.DistributorVisible = false
      this.DistributorStatus = false
      if (data === null || data.length <= 0) return
      this.relDistributors = data
    },
    addImgPreview() {
      this.imgDialog = true
      this.isGetImage = true
    },
    pickImg(data) {
      if (data && data.url !== '') {
        this.imgDialog = false
        var loc = this.$refs.editor
        var img = new Image()
        img.src = this.wximageurl + data.url
        if (loc.range) {
          loc.range.insertNode(img)
        } else {
          loc.$refs.content.appendChild(img)
          loc.focus()
          loc.restoreSelection()
        }
        this.form.viewcontent = loc.$refs.content.innerHTML
      }
    },
    closeImgDialog() {
      this.imgDialog = false
    },
    addThumbPreview() {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickThumb(data) {
      if (data && data.url !== '') {
        this.thumbDialog = false
        this.form.activity_cover = data.url
      }
    },
    closeThumbDialog() {
      this.thumbDialog = false
    },
    getDistributor(ids) {
      let param = { distributor_id: ids }
      getDistributorList(param).then(res => {
        this.relDistributors = res.data.data.list
      })
    },
    getCustomize(ids) {
      let param = { id: ids }
      getCustomizeList(param).then(res => {
        this.customizeList = res.data.data.list
      })
    },
    async onPickerPath() {
      const { data } = await this.$picker.pages({ multiple: false })
      this.form.page_url = {
        id: data[0].id,
        title: data[0].page_name,
        linkPage: data[0].page_type
      }
    },
    handleChangeType() {
      this.$set(this.form, 'video_cover', '')
      this.$set(this.form, 'page_url', null)
      this.$set(this.form, 'auto_play', false)
      this.$set(this.form, 'video_url', '')
    },
    hendleClear() {
      this.$set(this.form, 'page_url', null)
    },
    onSubmit() {
      this.form.distributor_ids = []
      if (this.relDistributors.length > 0) {
        this.relDistributors.forEach(distributor => {
          this.form.distributor_ids.push({ distributor_id: distributor.distributor_id });
        })
      }
      if (this.customizeList.length > 0) {
        this.form.customize_id = this.customizeList[0].id
      }
      if (this.form.type == 'selling') {
        if (!this.form.items) {
          this.$message.error('请选择商品');
          return;
        }

        // 封面视频处理
        if (this.form.video_url !== '' && this.form.video_cover === '') {
          this.$message.error('选择视频必须添加封面');
          return;
        }

        if (this.form.activity_cover == '' || this.form.activity_active_cover == '') {
          this.$message.error('请选择icon');
          return;
        }

        this.form.items.forEach(distributor => {
          this.form.distributor_ids.push({
            item_ids: distributor.item_id,
            limit_num: distributor.limit_num
          });
        })
        if(this.form.device_compare && !this.form.device_compare_img) {
        this.$message.error('请上传机型对比图片')
        return
      }
      if(this.form.device_recommend && !this.form.device_recommend_img) {
        this.$message.error('请上传机型推荐图片')
        return
      }
      }
      let param = {}
      if (this.form.id) {
        param = {
          distributor_ids: this.form.distributor_ids,
          activity_title: this.form.activity_title,
          activity_desc: this.form.activity_desc,
          activity_subtitle: this.form.activity_subtitle,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          customize_id: this.form.customize_id,
          type: this.form.type,
          platform: this.form.platform,
          activity_cover: this.form.activity_cover,
          activity_active_cover: this.form.activity_active_cover,
          items: this.form.items,
          page_url: this.form.page_url,
          video_url: this.form.video_url,
          video_cover: this.form.video_cover,
          auto_play: this.form.auto_play ? 1 : 0,
          media_type: this.form.media_type,
          device_compare:this.form.device_compare,
          device_recommend:this.form.device_recommend,
          device_recommend_img:this.form?.device_recommend_img||'',
          device_compare_img:this.form?.device_compare_img||''
        }
        updatePreferential(this.form.id, param).then(response => {
          const that = this
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.refresh()
              that.$router.go(-1)
            }
          })
        })
      } else {
        param = {
          distributor_ids: this.form.distributor_ids,
          activity_title: this.form.activity_title,
          activity_desc: this.form.activity_desc,
          customize_id: this.form.customize_id,
          type: this.form.type,
          platform: this.form.platform,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          activity_subtitle: this.form.activity_subtitle,
          activity_cover: this.form.activity_cover,
          activity_active_cover: this.form.activity_active_cover,
          items: this.form.items,
          page_url: this.form.page_url,
          video_url: this.form.video_url,
          video_cover: this.form.video_cover,
          auto_play: this.form.auto_play ? 1 : 0,
          media_type: this.form.media_type,
          device_compare:this.form.device_compare,
          device_recommend:this.form.device_recommend,
          device_recommend_img:this.form?.device_recommend_img||'',
          device_compare_img:this.form?.device_compare_img||''
        }
        createPreferential(param).then(res => {
          const that = this
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.refresh()
              that.$router.go(-1)
            }
          })
        })
      }
    },

  },
  mounted() {
    if (this.$route.query.platform) {
      this.form.platform = this.$route.query.platform
    }
    console.log(this.$route.query)
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      getPreferentialInfo(this.$route.query.id).then(res => {
        if (res.data.data) {
          if (res.data.data.distributor_ids && res.data.data.distributor_ids.length > 0) {
            let ids = []
            res.data.data.distributor_ids.forEach(item => {
              ids.push(item.distributor_id)
            })
            this.getDistributor(ids)
          }
          if (res.data.data.customize_id) {
            let customizeid = res.data.data.customize_id
            this.getCustomize(customizeid)
          }
          this.form.activity_title = res.data.data.activity_title
          this.form.activity_subtitle = res.data.data.activity_subtitle
          this.form.activity_cover = res.data.data.activity_cover
          this.form.activity_active_cover = res.data.data.activity_active_cover
          this.form.start_time = res.data.data.start_time
          this.form.end_time = res.data.data.end_time
          this.form.page_url = res.data.data.page_url
          this.form.media_type = Number(res.data.data.media_type) || 1
          this.form.device_compare = res.data.data.device_compare
          this.form.device_recommend = res.data.data.device_recommend
          this.form.device_recommend_img = res.data?.data?.device_recommend_img || ''
          this.form.device_compare_img = res.data?.data?.device_compare_img || ''
          this.form.type = res.data.data.type
          this.form.platform = res.data.data.platform
          this.form.video_cover = res.data.data.video_cover
          this.form.video_url = res.data.data.video_url
          this.form.auto_play = res.data.data.auto_play == 1 ? true : false
          this.form.activity_desc = res.data.data.activity_desc
          this.form.items = JSON.parse(res.data.data.items) || []
          this.created = res.data.data.created
          this.relItemsIds = JSON.parse(res.data.data.items) || []
        }
      })
    }

    //relItems
  }
}
</script>

<style scoped lang="scss">
.form-type {
  display: flex;
  align-items: flex-start;
}

.btn-content {
  display: flex;
  align-items: center;

  .btn-linkpath {
    padding: 0 8px;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: #fff;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    max-width: 150px;
    flex: 1;
    @include text-overflow();
    width: 150px;
    margin-right: 10px;
  }
}

.img-del {
  display: inline-block;
  vertical-align: top;
}

.vjs-custom-skin {
  width: 211px;
  height: 118px;
}

.icon-uploader {
  width: auto;
  height: 118px;
}

.video-uploader {
  width: auto;
  height: 118px;
}

.content-padded {
  padding: 40px;
}

.setting-view {
  position: relative;
  background: #fff;
}

.appmsg_input_area {
  overflow-y: auto;
  position: relative;
}

.appmsg_preview_area {
  position: absolute;
  left: 0;
  height: 100%;
}

.appmsg_preview_container {
  width: 250px;
  padding: 20px 0;
  border-right: 1px solid #e7e7eb;
  background-color: #f8f8f8;
  height: 100%;
}

.appmsg {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid #e7e7eb;
  background-color: #fff;
  color: #666;
}

.appmsg_content {
  position: relative;
}

.appmsg_preview_container .appmsg {
  overflow: visible;
  margin-bottom: 0;
  padding: 0;
}

.appmsg_preview_container .appmsg_content {
  min-height: 134px;
  margin-top: -1px;
}

.multi .appmsg_content {
  padding: 0;
}

.appmsg_preview_container .appmsg_item_wrp {
  cursor: pointer;
}

.appmsg_preview_container .appmsg_item_wrp.current {
  position: relative;
  z-index: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
}

.appmsg_preview_container .appmsg_item,
.appmsg_preview_container .first_appmsg_item {
  padding: 10px;
}

.appmsg_preview_container .first_appmsg_item {
  border-top: 1px solid #e7e7eb;
  position: relative;
}

.appmsg_preview_container .appmsg_item_wrp.current .first_appmsg_item,
.appmsg_preview_container .appmsg_item_wrp.current .appmsg_item {
  border-top-width: 0;
  padding: 9px 9px;
  border: 2px solid #43b548;
}

.cover_appmsg_item {
  position: relative;
  margin: 12px 14px;
}

.has_first_cover .cover_appmsg_item {
  margin: 12px 14px 12px;
}

.appmsg_preview_container .first_appmsg_item .cover_appmsg_item {
  margin: 0;
}

.appmsg_title {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.6;
  max-height: 88px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  color: #222;
}

.appmsg_preview_container .cover_appmsg_item .appmsg_title {
  max-height: 28px;
}

.appmsg_preview_container .first_appmsg_item .appmsg_title {
  color: #fff;
  padding: 0 8px;
}

.has_first_cover .cover_appmsg_item .appmsg_title {
  font-size: 14px;
  padding-top: 0;
  line-height: 28px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#99000000', endcolorstr='#99000000');
}

.appmsg_title a {
  display: block;
  color: #222;
}

.has_first_cover .cover_appmsg_item .appmsg_title a {
  padding: 0 8px;
  color: #fff;
}

.appmsg_thumb_wrp {
  height: 160px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.appmsg_preview_container .appmsg_thumb_wrp {
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.appmsg_thumb {
  width: 100%;
}

.appmsg_thumb.default {
  display: none;
  color: #c0c0c0;
  text-align: center;
  line-height: 160px;
  font-weight: 400;
  font-style: normal;
  background-color: #ececec;
  font-size: 14px;
}

.appmsg_preview_container .appmsg_thumb.default {
  line-height: 120px;
}

.editing .appmsg_thumb.default {
  display: block;
}

.icon_appmsg_thumb {
  width: 44px;
  height: 34px;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  font-size: 35px;
}

.appmsg_edit_mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(229, 229, 229, 0.85) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#d9e5e5e5', endcolorstr='#d9e5e5e5');
  text-align: center;
}

.appmsg_preview_container div.appmsg_edit_mask {
  top: auto;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.5) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#80000000', endcolorstr='#80000000');
  text-align: right;
}

.appmsg_preview_container .first_appmsg_item .appmsg_edit_mask {
  line-height: 188px;
}

.appmsg_preview_container .first_appmsg_item:hover .appmsg_edit_mask {
  display: block
}

.appmsg_preview_container .appmsg_item_wrp.current .appmsg_edit_mask {
  bottom: -1px;
}

.editing .cover_appmsg_item:hover .appmsg_edit_mask,
.editing .appmsg_item:hover .appmsg_edit_mask {
  display: block
}

.icon20_common {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  line-height: 1.5;
  color: #fff;
}

.appmsg_edit_mask a {
  margin-left: 8px;
  margin-right: 8px;
}

.appmsg_preview_container div.appmsg_edit_mask .sort_up_white,
.appmsg_preview_container div.appmsg_edit_mask .sort_down_white {
  float: left;
  margin-top: 10px;
}

.appmsg_item {
  position: relative;
  padding: 12px 14px;
}

.appmsg_preview_container .appmsg_item,
.appmsg_preview_container .first_appmsg_item {
  padding: 10px;
}

.multi .appmsg_item {
  border-top: 1px solid #e7e7eb;
}

.multi .appmsg_item:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.multi .appmsg_item .appmsg_thumb_wrp {
  float: right;
  width: 78px;
  height: 78px;
  margin-left: 14px;
}

.appmsg_preview_container .appmsg_item .appmsg_thumb {
  width: 78px;
  height: 78px;
}

.appmsg_item .appmsg_thumb.default {
  line-height: 78px;
  font-size: 24px;
}

.appmsg_preview_container .appmsg_item .appmsg_thumb.default {
  line-height: 78px;
}

.create_access_primary {
  display: block;
  border: 2px dotted #d9dadc;
  line-height: 60px;
  text-align: center;
  margin-bottom: 20px;
}

.appmsg_add {
  margin: 20px 14px;
}

.appmsg_preview_container .appmsg_add {
  margin: 0;
  border-top-width: 0;
  line-height: 90px;
}

.icon35_common {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  display: inline-block;
  font-size: 35px;
  color: #ccc;
}

.create_access_primary i {
  cursor: pointer;
  overflow: hidden;
}

.appmsg_tpl_container {
  padding: 20px 0;
  border-left: 1px solid #e7e7eb;
  background-color: #fff;
  height: 100%;
}

.appmsg_container_hd {
  padding: 10px 20px 0;
}

.appmsg_container_title {
  font-weight: 400;
}

.appmsg_container_bd {
  padding: 15px;
  position: relative;
}

.appmsg_edit_highlight_area {
  padding-bottom: 80px;
  background-color: #fcfcfc;

  .header-title {
    margin-bottom: 20px;
  }

  .header_tips {
    margin-bottom: 10px;
  }

  .content-num {
    color: #999;
    text-align: right;
  }
}

.appmsg_input_area .cover_preview {
  float: left;
  position: relative;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.appmsg_input_area .cover_preview.first_appmsg_cover {
  width: 188px;
}

.tool_area_wrp {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #d9dadc;
}

.tool_area_wrp .tool_area {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.flex-video {
  display: flex;
}

.tool_area_wrp .tool_area .tool_bar {
  padding: 11px 210px 11px 250px;
}

#submitContent {
  height: 0;
  overflow: hidden;
}

.setting-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 200px;
  background: #ffffff;
  border-top: 1px solid #efefef;
}

.flex-video {
  display: flex;
}
</style>
<style lang="scss">
.recommend-selling{
  .el-form-item__content{
    display: flex;
    align-items: center;
  }
  .sp-image-picker .placeholder,.image-item{
    margin-left: 10px;
    height: 36px!important;
    width: 120px!important;
    .add-text{
      display: none;
    }
  }
}
</style>
