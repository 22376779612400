<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" icon="plus" @click="addActivity">添加活动</el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="0" />
        <el-tab-pane label="未开始" name="1" />
        <el-tab-pane label="进行中" name="2" />
        <el-tab-pane label="已失效" name="3" />
        <el-table :data="activityData" :height="wheight-200" v-loading="loading">
          <el-table-column prop="activity_id" label="编号" width="80" />
          <el-table-column prop="activity_name" label="活动名称" min-width="180" />
          <el-table-column prop="preheat_type" label="活动类型" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.support_only_preheat == 1">支持预热</span>
              <span v-else-if="scope.row.support_only_preheat == 0">全流程</span>
            </template>
          </el-table-column>
          <el-table-column prop="start_time" label="开始时间" min-width="150" />
          <el-table-column prop="end_time" label="结束时间" min-width="150" />
          <el-table-column prop="status_msg" label="状态" min-width="100" />
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.status_desc == 'waiting' || scope.row.status_desc == 'going'" @click="closeLimitPromotion(scope.row)">取消活动</el-button>
              <el-button type="text" @click="editActivity(scope.row, 'detail')">查看</el-button>
              <el-button type="text" v-if="scope.row.status_desc == 'waiting' || scope.row.status_desc == 'going'" @click="editActivity(scope.row, 'edit')">编辑活动</el-button>
              <ImportDialog
                buttonName="上传店铺"
                buttonType="text"
                fileName="上传活动店铺模版"
                fileType="upload_presale_distributor"
                :relId="scope.row.activity_id"
                v-if="scope.row.status_desc != 'invalid'"
              />
              <el-button type="text" @click="onShowShopDialog(scope.row)">查看店铺</el-button>
              <el-button type="text" v-if="scope.row.activity_page == '1' && scope.row.status_desc != 'invalid'" @click="submitItemIsActivePage(scope.row)">切换普通页面</el-button>
              <el-button type="text" v-if="scope.row.activity_page !== '1' && scope.row.status_desc != 'invalid'" @click="submitItemIsActivePage(scope.row)">切换活动页面</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>
    <router-view></router-view>
    <StoreDialog
      :visible="shopDialog"
      :params="{ activity_id: shopSelectRow.activity_id, status_desc: shopSelectRow.status_desc }"
      url="/presale/distributor/list"
      @onCloseDialog="onShopCloseChange"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPresaleList, cancelPresale } from '@/api/promotions'
import { itemIsActivePage } from '@/api/goods'
import ImportDialog from '@/components/importDialog'
import StoreDialog from './comps/store-dialog'

export default {
  components: {
    StoreDialog,
    ImportDialog
  },
  data () {
    return {
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        status: '0'
      },
      activeName: '0',
      shopDialog: false,
      shopSelectRow: {},
      activityData: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name
      this.params.status = tab.name
      this.params.page = 1
      this.getActivityList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getActivityList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getActivityList()
    },
    addActivity () { // 添加物料弹框
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    editActivity (row, type) { // 编辑物料弹框
      this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: row.activity_id, type } })
    },
    getActivityList () {
      this.loading = true
      getPresaleList(this.params).then(response => {
        this.activityData = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    async closeLimitPromotion(row) {
      this.$confirm('此操作将永久终止该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          await cancelPresale({ activity_id: row.activity_id }).then(res => {
            this.$message({
              type: 'success',
              message: '取消成功!'
            })
            this.getActivityList()
          })
        })
        .catch(() => { })
    },
    submitItemIsActivePage (row) {
      console.log(row)
      this.loading = true
      const params = {
        'type': 'presaleActivity',
        'items': [{"presale_id":row.activity_id}],
        'status': row.activity_page == '1' ? '0' : '1'
      }
      itemIsActivePage(params).then(res => {
        if (res.data.data.status) {
          this.$message.success('操作成功')
          this.getActivityList()
        }
      })
      this.loading = false
    },
    onShowShopDialog (row) {
      this.shopDialog = true
      this.shopSelectRow = row
    },
    onShopCloseChange () {
      this.shopDialog = false
      this.shopSelectRow = {}
      this.getActivityList()
    },
  },
  mounted () {
    this.getActivityList()
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.icon-search-plus {
  cursor: pointer;
}
.text-muted {
  color: #999;
}
</style>
