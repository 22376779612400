<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <div>
        <el-date-picker
          v-model="datePickerValue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="报名开始日期"
          end-placeholder="报名结束日期"
          value-format="timestamp"
          @change="searchList"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-date-picker
          v-model="signInDatePickerValue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="签到开始日期"
          end-placeholder="签到结束日期"
          value-format="timestamp"
          @change="searchList"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-input v-model="params.sign_in_code" placeholder="请输入报名序列号" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.activity_name" placeholder="请输入活动名称" class="input-b filter-input" @change="searchList" clearable></el-input>
<!--        <el-input v-model="params.reservation_person_mobile" placeholder="请输入报名手机号" class="input-b filter-input" @change="searchList" clearable></el-input>-->
        <el-select v-model="params.gift_issue_status" placeholder="礼品发放状态" class="filter-input" @change="searchList" clearable>
          <el-option label="礼品已发放" value="1"></el-option>
          <el-option label="礼品未发放" value="2"></el-option>
        </el-select>
      </div>
      <div v-if="login_type !== 'distributor'">
        <el-input v-model="params.distributor_name" placeholder="请输入店铺名称" class="input-m filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.shop_code" placeholder="请输入AppleID" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.dealer_name" placeholder="请输入经销商名称" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.dealer_hqid" placeholder="请输入经销商HQID" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.distributor_vad" placeholder="请输入分销商VAD" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-input v-model="params.channel_rtm" placeholder="请输入渠道RTM" class="input-b filter-input" @change="searchList" clearable></el-input>
        <el-select v-model="params.source_type" placeholder="来源类型" class="filter-input" @change="searchList" clearable>
          <el-option v-for="(item, key) in sourceType" :key="`source-type-item__${key}`" :label="item" :value="key"></el-option>
        </el-select>
        <el-select v-model="params.source_channel" placeholder="来源渠道" class="filter-input" @change="searchList" clearable>
          <el-option v-for="(item, key) in sourceChannel" :key="`source-channel-item__${key}`" :label="item" :value="key"></el-option>
        </el-select>
      </div>
      <el-select class="input-m mt10" placeholder="使用平台" v-model="params.platform" clearable @change="searchList">
        <el-option label="微信小程序" :value="'wxapp'"></el-option>
        <el-option label="抖音小程序" :value="'byte_mini'"></el-option>
      </el-select>
      <div>
        <el-button type="primary" @click="exportData">导出</el-button>
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" style="margin-top: 15px">
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="有效记录" name="5"></el-tab-pane>
        <el-tab-pane label="未签到" name="1"></el-tab-pane>
        <el-tab-pane label="已签到" name="2"></el-tab-pane>
        <el-tab-pane label="已取消" name="3"></el-tab-pane>
        <el-tab-pane label="已过期" name="4"></el-tab-pane>
        <el-table :data="list" style="width: 100%" v-loading="loading" element-loading-text="数据加载中">

          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="right" inline class="demo-table-expand" label-width="140px">
                <el-form-item label="经销商：">
                  <span>{{ scope.row.distributor_info.dealer_name }}</span>
                </el-form-item>

                <el-form-item label="报名人：">
                  <span v-if="scope.row.reservation_person_name">
                    {{ scope.row.reservation_person_name }}
                    <PiiInfo pageType='3' memberType='receiver_name' optionScene="offline_activity"  :associatedId='scope.row.id'/>
                  </span>
                </el-form-item>

                <el-form-item label="门店地址：">
                  <span>{{ scope.row.distributor_info.city + scope.row.distributor_info.area + scope.row.distributor_info.address }}</span>
                </el-form-item>

                <el-form-item label="联系方式：">
                  <span v-if="scope.row.reservation_person_mobile">
                    {{ scope.row.reservation_person_mobile }}
                    <PiiInfo pageType='3' memberType='receiver_mobile' optionScene="offline_activity"  :associatedId='scope.row.id'/>
                  </span>
                </el-form-item>

                <el-form-item label="门店电话：">
                  <span>{{ scope.row.distributor_info.mobile || '暂无' }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>

          <el-table-column prop="id" width="60" label="编号"></el-table-column>
          <el-table-column prop="" width="80" label="来源类型">
            <template slot-scope="scope">
              {{sourceType[scope.row.source_type] || ''}}
            </template>
          </el-table-column>
          <el-table-column width="150" label="使用平台">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.platform === 'wxapp'" type="primary" size="mini">微信小程序</el-tag>
              <el-tag v-if="scope.row.platform === 'byte_mini'" type="primary" size="mini">抖音小程序</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="id" width="80" label="来源渠道">
            <template slot-scope="scope">
              {{scope.row.source_type == 2 ? scope.row.source_channel : (sourceChannel[scope.row.source_channel] || '')}}
            </template>
          </el-table-column>
          <el-table-column prop="activity_id" width="250" label="报名信息">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.sign_in_code }}</span>
                <el-tooltip effect="dark" content="复制" placement="top-start"><i v-clipboard:copy="scope.row.sign_in_code" v-clipboard:success="onCopy" class="el-icon-document-copy"></i></el-tooltip>
              </div>
              <div>
                <i class="el-icon-s-shop"></i>{{scope.row.distributor_info.name}}
              </div>
              <div>
                <i class="el-icon-time"></i>{{scope.row.created | datetime('yyyy-MM-dd hh:mm:ss')}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" width="100" label="AppleId">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.distributor_info.shop_code}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="报名信息">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.session_date_time }} {{ scope.row.session_name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="活动信息">
            <template slot-scope="scope">
              <div title="活动名称">
                <span>{{ scope.row.activity_info.activity_title}}</span>
              </div>
              <div title="报名方式">
                <el-tag v-if="scope.row.activity_info.join_type === 'appointment'" size="mini">预约报名</el-tag>
                <el-tag v-else size="mini">现场报名</el-tag>
              </div>
              <div title="核销方式">
                <el-tag v-if="scope.row.activity_info.sign_in_system === 'local'" size="mini" type="info">数字签到码</el-tag>
                <el-tag v-else-if="scope.row.activity_info.sign_in_system === 'guestbook'" size="mini" type="info">GuestBook</el-tag>
                <el-tag v-else-if="scope.row.activity_info.sign_in_system === 'wecom'" size="mini" type="info">Wecom</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" width="120" label="状态">
            <template slot-scope="scope">
              <div>
                <el-tag :type="scope.row.tag_type" size="mini">{{ scope.row.sign_in_status_desc}}</el-tag>
              </div>
              <div v-if="scope.row.gifts_issued == 1">
                <el-tag :type="scope.row.tag_type || 'primary'" size="mini">礼品已发放</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" width="120" label="查看">
            <template slot-scope="scope">
              <el-button type="text" @click="signUpDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.sign_in_system !== 'wecom'">
                <el-button
                  :disabled="scope.row.allow_sign_in === 0"
                  v-if="showSignInBtn(scope.row)"
                  type="success"
                  size="mini"
                  @click="handleSignIn(scope.row)">
                  签到
                </el-button>
                <el-button
                  v-if="showGiftBtn(scope.row)"
                  type="primary"
                  size="mini"
                  @click="handleGiftIssue(scope.row)">
                  发放礼品
                </el-button>
                <el-button
                  v-if="showCancelBtn(scope.row)"
                  type="danger"
                  size="mini"
                  @click="handleSignInCancel(scope.row)">
                  取消
                </el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog
      title="签到"
      :visible.sync="signInVisible"
      width="450px"
      :before-close="signInHandleClose">
      <span>签到码：</span><el-input class="input-b" v-model="signCode"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="signInVisible = false">取 消</el-button>
        <el-button type="primary" @click="signIn" :loading="signInLoading">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="发放礼品"
      :visible.sync="giftVisible"
      width="450px"
      :before-close="signInCancelHandleClose">
      <template>
        <el-select :multiple="true" v-model="giftValue" placeholder="请选择礼品">
          <el-option
            v-for="(item, index) in gifts"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="giftVisible = false">稍后发放</el-button>
        <el-button type="primary" @click="giftIssue" :loading="giftIssueLoading">确认发放</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="取消"
      :visible.sync="signInCancelVisible"
      width="450px"
      :before-close="signInCancelHandleClose">
      <span>取消原因：</span>
      <el-select v-model="cancelReason" placeholder="请选择">
        <el-option
          v-for="item in cancelReasons"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
<!--      <el-input class="input-b" v-model="cancelReason"></el-input>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="signInCancelVisible = false">取 消</el-button>
        <el-button type="primary" @click="signInCancel" :loading="signInCancelLoading">确 认</el-button>
      </span>
    </el-dialog>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getOfflineActivitySignUpLog,
  offlineActivitySignIn,
  offlineActivitySignUpCancel,
  issuedGift,
} from '@/api/promotions'
import PiiInfo from '@/components/piiInfo/index'

export default {
  provide() {
    return {
    }
  },
  components: {
    PiiInfo
  },
  data () {
    return {
      sourceType: {
        0: '其他',
        1: '企微转发',
        2: '门店活动页参数',
        // 3: '直接访问',
        4: '到店',
        5: '页面转发',
      },
      sourceChannel: {
        other: '其他',
        shopping_guide_forward: '导购转发',
        // utm_wecom: 'UTM',
        // mini_program: '小程序',
        distributor_scan_offlien_activity_page: '扫码门店活动详情页',
        platform_friend_share: '平台好友分享',
        platform_wechat_moments_share: '平台朋友圈分享',
        distributor_friend_share: '门店好友分享',
        distributor_wechat_moments_share: '门店朋友圈分享'
      },
      cancelReasons: [
        {label: '临时闭店', value: '临时闭店'},
        {label: '活动改期', value: '活动改期'},
      ],
      activeName: '0',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        activity_name: '',
        start_time: '',
        end_time: '',
        sign_in_code: '',
        sign_code: '',
        distributor_name: '',
        shop_code: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
        reservation_person_mobile: '',
        gift_issue_status: '',
        sign_in_status: '0',
        platform: '',
        signin_start_time: '',
        signin_end_time: '',
      },
      datePickerValue: [],
      signInDatePickerValue: [],
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
      signInVisible: false,
      signCode: '',
      signInRow: {},
      signInLoading: false,
      signInCancelRow: {},
      signInCancelLoading: false,
      signInCancelVisible: false,
      cancelReason: '',
      giftVisible: false,
      giftIssueRow: {},
      giftIssueLoading: false,
      gifts: [],
      giftValue: '',
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
    ])
  },
  methods: {
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.sign_in_status = tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    searchList () {
      this.params.page = 1
      this.getOfflineActivityList()
    },
    getOfflineActivityList() {
      this.loading = true
      if (this.datePickerValue) {
        this.params.start_time = this.datePickerValue[0]
        this.params.end_time = this.datePickerValue[1]
      } else {
        this.params.start_time = this.params.end_time = ''
      }
      if (this.signInDatePickerValue) {
        this.params.signin_start_time = this.signInDatePickerValue[0]
        this.params.signin_end_time = this.signInDatePickerValue[1]
      } else {
        this.params.signin_start_time = this.params.signin_end_time = ''
      }
      let filter = this.params
      getOfflineActivitySignUpLog(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }) . catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    onCopy() {
      this.$notify({
        message: '复制成功',
        type: 'success',
      })
    },
    handleSignIn(row) {
      this.signInRow = row
      console.log(row)
      this.signInVisible = true
    },
    signInHandleClose() {
      this.signCode = ''
      this.signInVisible = false
    },
    signIn() {
      this.signCode = this.signCode.trim()
      if (!this.signCode.trim()) {
        this.$message.error('请输入签到码')
        return;
      }
      this.signInLoading = true
      let params = {
        sign_in_code: this.signInRow.sign_in_code,
        id: this.signInRow.id,
        activity_id: this.signInRow.activity_id,
        sign_code: this.signCode,
      }
      offlineActivitySignIn(params).then(response => {
        this.signInLoading = false
        if (response.data.data.success) {
          this.$message.success('签到成功');
          this.signInVisible = false
          if (this.signInRow.activity_info.gift.length > 0) {
            this.giftIssueRow = JSON.parse(JSON.stringify(this.signInRow))
            this.gifts = this.giftIssueRow.activity_info.gift
            this.giftVisible = true
          }
          this.getOfflineActivityList()
        } else {
          this.$message.error('签到失败');
        }
      }).catch(error => {
        this.signInLoading = false
        // this.$message.error('签到失败');
      })
    },
    showSignInBtn (row) {
      return this.login_type === 'distributor' && row.signed_in == 0 && row.cancel_status == 0 && row.sign_in_system === 'local' && row.need_sign_in !== 'false'
    },
    showCancelBtn (row) {
      return this.login_type === 'distributor' && row.signed_in == 0 && row.cancel_status == 0 && row.need_sign_in !== 'false'
    },
    showGiftBtn (row) {
      return this.login_type === 'distributor' && row.activity_info.gift.length > 0 && (row.signed_in == 1 || row.need_sign_in === 'false') && row.cancel_status == 0 && row.gifts_issued == 0
    },
    handleSignInCancel(row) {
      this.signInCancelRow = row
      this.signInCancelVisible = true
    },
    signInCancelHandleClose() {
      this.signInCancelVisible = false
      this.giftVisible = false
    },
    signInCancel() {
      this.signInCancelLoading = true
      let params = {
        sign_in_code: this.signInCancelRow.sign_in_code,
        id: this.signInCancelRow.id,
        activity_id: this.signInCancelRow.activity_id,
        cancel_reason: this.cancelReason,
      }
      offlineActivitySignUpCancel(params).then(response => {
        this.signInCancelLoading = false
        if (response.data.data.success) {
          this.$message.success('取消成功');
          this.signInCancelVisible = false
          this.getOfflineActivityList()
        } else {
          this.$message.error('取消失败');
        }
      }).catch(error => {
        this.signInCancelLoading = false
        // this.$message.error('取消失败');
      })
    },
    handleGiftIssue (row) {
      this.giftIssueRow = row
      this.gifts = row.activity_info.gift
      this.giftVisible = true
    },
    giftIssue () {
      this.giftIssueLoading = true
      let params = {
        sign_in_code: this.giftIssueRow.sign_in_code,
        id: this.giftIssueRow.id,
        activity_id: this.giftIssueRow.activity_id,
        gift_value: this.giftValue,
      }
      issuedGift(params).then(response => {
        this.giftIssueLoading = false
        if (response.data.data.success) {
          this.$message.success('更新成功');
          this.giftVisible = false
          this.getOfflineActivityList()
        } else {
          this.$message.error('更新失败');
        }
      }).catch(error => {
        this.giftIssueLoading = false
        // this.$message.error('取消失败');
      })
    },
    signUpDetail(row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {id: row.id, sign_in_code: row.sign_in_code}})
    },

    exportData() {
      this.loading = true
      if (this.datePickerValue) {
        this.params.start_time = this.datePickerValue[0]
        this.params.end_time = this.datePickerValue[1]
      } else {
        this.params.start_time = this.params.end_time = ''
      }
      let filter = {
        ...this.params,
        is_export: 1
      }
      getOfflineActivitySignUpLog(filter).then(response => {
        this.$message.success('导出成功')
        this.loading = false
      }) . catch(error => {
        this.loading = false
      })
    }

  },
  mounted () {
    this.getOfflineActivityList()
  },
  watch: {
    '$route' () {
      this.getOfflineActivityList()
    }
  },
}
</script>

<style>
  .filter-input{
    margin-bottom: 10px
  }
</style>
