<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row>
          <shop-select v-if="$store.getters.login_type != 'distributor'" distributors  @update="storeSearch"></shop-select>
          <el-date-picker
            v-model="create_time"
            type="daterange"
            value-format="yyyy/MM/dd"
            placeholder="根据添加时间筛选"
            @change="dateChange">
          </el-date-picker>
          <el-input  class="input-b" placeholder="请输入活动ID" clearable v-model="params.marketing_id" @change="getFullMinusLists(false)">
            <template slot="prepend">活动ID</template>
          </el-input>
        </el-row>
      <el-row>
          <el-button type="primary" icon="el-icon-search" size="medium" @click="getFullMinusLists(false)">查询</el-button>
          <el-button type="primary" icon="el-icon-search" size="medium" @click="exportData">导出</el-button>
          <el-button type="primary" icon="el-icon-search" size="medium" @click="showDealerCard">导出经销商满减活动</el-button>
          <el-button size="medium" type="primary" icon="plus" @click="addActivityData">添加满减促销</el-button>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="待开始" name="waiting"></el-tab-pane>
        <el-tab-pane label="进行中" name="ongoing"></el-tab-pane>
        <el-tab-pane label="已结束" name="end"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="marketing_id" width="60" label="编号"></el-table-column>
          <el-table-column prop="marketing_name" min-width="150" label="满减促销名称"></el-table-column>
          <el-table-column label="规则" min-width="200">
              <template slot-scope="scope">
                  <div v-for="(item,index) in scope.row.condition_value" :key="index">
                    <span v-if="scope.row.condition_type == 'quantity'">
                    消费满{{item.full}}件，减 {{item.minus}}元
                    </span>
                    <span v-if="scope.row.condition_type == 'totalfee'">
                    消费满{{item.full}}元，减 {{item.minus}}元
                    </span>
                  </div>
              </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="150" label="有效期">
            <template slot-scope="scope">
              <div> {{scope.row.start_date}}</div><div>~</div><div>{{scope.row.end_date}}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="70" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 'ongoing'">进行中</span>
              <span v-if="scope.row.status == 'waiting'">未开始</span>
              <span v-if="scope.row.status == 'end'">已结束</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <div class="operating-icons">
                <el-button v-if="scope.row.use_bound != 0" type="text" @click="viewItemList(scope.row.marketing_id)">查看商品</el-button>
                <el-button v-else type="text" @click="viewItemList('all', scope.row.item_type)">全部商品</el-button>
                <el-button type="text" v-if="scope.row.status !== 'end'" @click="updateStatusCommunityAction(scope.row)">终止</el-button>
                <el-button type="text" @click="viewDetail(scope.row)">查看详情</el-button>
                <el-button v-if="scope.row.status != 'end'" type="text" @click="editActivityAction(scope.$index, scope.row)">编辑</el-button>
                <el-button v-if="scope.row.use_shop == 1" type="text" @click="showDistributors(scope.row)">查看店铺</el-button>
                <el-button v-if="scope.row.use_shop == 2" type="text" @click="showDealers(scope.row)">查看经销商</el-button>
                <ImportDistributor
                  v-if="scope.row.use_shop === 1"
                  buttonName="上传适用店铺"
                  buttonType="text"
                  fileName="上传适用店铺模版"
                  fileType="full_minus_upload_distributor"
                  :relId="scope.row.marketing_id"
                ></ImportDistributor>
                <ImportDistributor
                  v-if="scope.row.use_shop === 2"
                  buttonName="上传适用经销商"
                  buttonType="text"
                  fileName="上传适用经销商模版"
                  fileType="full_minus_upload_dealer"
                  :relId="scope.row.marketing_id"
                ></ImportDistributor>
<!--                <el-button type="text" @click="deleteActivityAction(scope.row)">删除</el-button>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-padded content-center">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="params.page"
            :page-sizes="[10,20,50]"
            :total="total_count"
            :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>

      <el-dialog title="活动商品列表" :visible.sync="activityItemDialog" :before-close="handleCancel" width="70%">
        <template>
          <el-pagination
            background
            layout="total"
            @current-change="handleGoodsCurrentChange"
            @size-change="handleGoodsSizeChange"
            :current-page.sync="activityItemParams.page"
            :page-sizes="[10,20,50]"
            :total="activityItemTotalCount"
            :page-size="activityItemParams.pageSize">
          </el-pagination>
          <el-table :data="activityItemListsData" :height="wheight-190" v-loading="ItemLoading">
            <el-table-column prop="item_id" label="id" width="60"></el-table-column>
            <el-table-column prop="pics[0]" label="图片" width="80">
              <template slot-scope="scope">
                <img :src="wximageurl + scope.row.pics[0]" width="50" height="50" />
              </template>
            </el-table-column>
            <el-table-column prop="item_name" label="名称"></el-table-column>
            <el-table-column prop="price" label="价格" width="100">
              <template slot-scope="scope">
                {{cursymbol}}{{scope.row.price/100}}
              </template>
            </el-table-column>
            <el-table-column prop="item_spec_desc" label="规格">
              <template slot-scope="scope">
                <el-col>{{scope.row.item_spec_desc}}</el-col>
              </template>
            </el-table-column>
            <el-table-column prop="store" label="库存" width="70"></el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleGoodsCurrentChange"
            @size-change="handleGoodsSizeChange"
            :current-page.sync="activityItemParams.page"
            :page-sizes="[10,20,50]"
            :total="activityItemTotalCount"
            :page-size="activityItemParams.pageSize">
          </el-pagination>
        </template>
      </el-dialog>
      <el-dialog title="关联经销商的活动" :visible.sync="useDealerMarketing"  :before-close="handleDealerCancel">
        <template>
          <div class="row" style="margin-bottom: 15px">
            <dealer-select  @update="dealerSearch"></dealer-select>
          </div>
          <el-input :span="10" v-model="fetchDealerParams.marketing_name"  placeholder="请输入活动名称" style="width: 200px"/>
          <template>
            <el-select v-model="fetchDealerParams.search_status" placeholder="请选择">
              <el-option :key="'waiting'" :label="'待开始'" :value="'waiting'"></el-option>
              <el-option :key="'ongoing'" :label="'进行中'" :value="'ongoing'"></el-option>
              <el-option :key="'end'" :label="'已结束'" :value="'end'"></el-option>
            </el-select>
          </template>
          <el-button type="primary" @click="doSearche()">搜索</el-button>
          <el-button type="primary" style="margin-left: 100px" @click="searchList(true)">确认导出</el-button>
          <el-table ref="singleTable"  @selection-change="handleSelectionCard" :data="dealerMarketingList"
                    style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="marketing_id" label="活动ID"></el-table-column>
            <el-table-column prop="marketing_name" label="活动名称"></el-table-column>
            <el-table-column prop="title" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 'ongoing'">进行中</span>
                <span v-if="scope.row.status == 'waiting'">未开始</span>
                <span v-if="scope.row.status == 'end'">已结束</span>
              </template>
            </el-table-column>

          </el-table>
          <div class="content-padded content-center">
            <el-pagination
              :current-page.sync="fetchDealerParams.page"
              layout="total,sizes, prev, pager, next, jumper"
              @size-change="handleDealerSizeChange"
              @current-change="handleDealerChange"
              :total="dealer_pagers.total"
            >
            </el-pagination>
          </div>
        </template>

      </el-dialog>

      <el-dialog
        v-loading="distributorLoading"
        :before-close="beforeCloseDistributorDialog"
        title="店铺"
        :visible.sync="distributorDialogVisible"
        width="60%">
        <div>
          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="getBindStoreList" size="medium"></el-button>
        </div>

        <el-table
          height="600"
          v-if="distributorList.length > 0"
          :data="distributorList"
          style="line-height: normal"
        >
          <template>
            <el-table-column
              label="ID"
              prop="distributor_id"
              width="90"
            ></el-table-column>
            <el-table-column
              label="AppleID"
              prop="shop_code"
              width="120"
            ></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              show-overflow-tooltip
            ></el-table-column>
          </template>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteDistributor(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindStoreCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.page_size">
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="distributorDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog
        v-loading="distributorLoading"
        :before-close="beforeCloseDistributorDialog"
        title="经销商"
        :visible.sync="dealerDialogVisible"
        width="60%">
<!--        <div>
          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="getBindStoreList" size="medium"></el-button>
        </div>-->

        <el-table
          height="600"
          v-if="dealerList.length > 0"
          :data="dealerList"
          style="line-height: normal"
        >
          <template>
            <el-table-column
              label="ID"
              prop="dealer_id"
              width="90"
            ></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column
              prop="dealer_hqid"
              label="经销商HQID"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="经销商HQID" prop="distributor_hqid"></el-table-column>
            <el-table-column label="经销商VAD" prop="distributor_vad"></el-table-column>
          </template>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteDistributor(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindDealerCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.page_size">
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="distributorDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {
    getMarketingActivityList,
    getMarketingActivityItemList,
    removeMarketingActivity,
    getMarketingDistributorList,
    deleteMarketingDistributor,
  } from '../../../../api/promotions'
  import shopSelect from '@/components/shopSelect'
  import ImportDistributor from '@/components/importDistributor'
  import dealerSelect from '@/components/dealerSelect'

  export default {
    provide() {
      return {
        refresh: this.getFullMinusLists
      }
    },
    components: {
      dealerSelect,
      shopSelect,
      ImportDistributor
    },
    data () {
      return {
        distributorLoading: false,
        distributorDialogVisible: false,
        bindStorePagination: {
          dealer_name: '',
          distributor_name: '',
          shop_code: '',
          status: '',
          page: 1,
          page_size: 10,
        },
        distributorList: [],
        dealerList: [],
        totalStoreCount: 0,
        dealerDialogVisible: false,
        activeName: 'all',
        cursymbol: '￥',
        loading: false,
        create_time: '',
        useDealerMarketing: false,
        fetchDealerParams: {
          marketing_type: 'full_minus',
          marketing_name: '',
          search_status: '',
          page: 1,
          pageSize: 10
        },
        dealerMarketingList: [],
        dealer_pagers: {
          total: 0
        },
        exportMarketing: [],
        params: {
          page: 1,
          pageSize: 20,
          marketing_id: '',
          marketing_type: 'full_minus',
          item_type: '',
          status: '',
          start_time: '',
          end_time: '',
        },
        activityItemParams: {
            page: 1,
            pageSize: 20,
        },
        activityItemTotalCount: 0,
        activityItemListsData: [],
        total_count: 0,
        list: [],
        activityItemDialog: false,
        ItemLoading: false,
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      deleteDistributor(row){
        const params = {
          marketing_id: this.marketing_id,
        }
        if (row.hasOwnProperty('distributor_id')) { // 门店
          params.id = row.distributor_id
        } else { // 经销商
          params.id = row.dealer_id
        }
        deleteMarketingDistributor(params) .catch (error => {
          this.distributorLoading = false
        }).then(res => {
          if (row.hasOwnProperty('distributor_id')) { // 门店
            this.getBindStoreList()
          } else { // 经销商
            this.getBindDealerList()
          }
        })
      },
      beforeCloseDistributorDialog(){
        this.bindStorePagination.page = 1
        this.bindStorePagination.distributor_name = ''
        this.bindStorePagination.shop_code = ''
        this.bindStorePagination.status = ''
        this.distributorList = []
        this.dealerList = []
        this.distributorDialogVisible = false
        this.dealerDialogVisible = false
      },
      bindStoreCurrentChange(val) {
        this.bindStorePagination.page = val
        this.getBindStoreList()
      },
      bindDealerCurrentChange(val) {
        this.bindStorePagination.page = val
        this.getBindDealerList()
      },
      async getBindStoreList () {
        this.distributorLoading = true
        const { data: bindList } = await getMarketingDistributorList({
          marketing_id: this.marketing_id,
          ...this.bindStorePagination
        }) .catch (error => {
          this.distributorLoading = false
        })
        this.distributorLoading = false
        this.totalStoreCount = bindList.data.shop_ids.length
        this.distributorList = bindList.data.storeLists
      },
      async getBindDealerList () {
        this.distributorLoading = true
        const { data: bindList } = await getMarketingDistributorList({
          marketing_id: this.marketing_id,
          ...this.bindStorePagination
        }) .catch (error => {
          this.distributorLoading = false
        })
        this.distributorLoading = false
        this.totalStoreCount = bindList.data.shop_ids.length
        this.dealerList = bindList.data.dealersLists
      },
      showDistributors(row) {
        this.marketing_id = row.marketing_id
        this.distributorDialogVisible = true
        this.getBindStoreList()
      },
      showDealers(row) {
        this.marketing_id = row.marketing_id
        this.dealerDialogVisible = true
        this.getBindDealerList()
      },
      // 切换tab
      handleClick(tab, event) {
        this.activeName = tab.name
        this.params.status = tab.name == 'all' ? '' : tab.name
        this.params.page = 1
        this.getFullMinusLists()
      },
      storeSearch (val) {
        val && val.shop_id
        this.params.store_id = val.shop_id
        this.params.page = 1
        this.getFullMinusLists()
      },
      itemTypeChange () {
        this.params.page = 1
        this.getFullMinusLists()
      },
      addActivityData () {
        this.$router.push({path: this.matchHidePage('editor')})
      },
      editActivityAction (index, row) {
        this.$router.push({path: this.matchHidePage('editor/') + row.marketing_id})
      },
      deleteActivityAction (row) {
        var msg = '你确定要删除该活动吗?'
        this.$confirm(msg, '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              removeMarketingActivity({marketing_id: row.marketing_id}).then(res => {
                this.getFullMinusLists()
                this.$message({
                  message: '删除活动成功',
                  type: 'success',
                  duration: 5 * 1000
                })
              })
            }
            done()
          }
        })
      },
      viewDetail(row) {
        this.$router.push({path: this.matchHidePage('editor/') + row.marketing_id, query: {isnodata: true}})
      },
      viewItemList (id, itemType) {
        if (id == 'all') {
          if (itemType == 'normal') {
            this.$router.push({path: this.matchInternalRoute('goodsphysical')})
          } else {
            this.$router.push({path: this.matchInternalRoute('servicegoods')})
          }
        } else {
          this.ItemLoading = true
          this.activityItemDialog = true
          this.activityItemParams.marketing_id = id
          getMarketingActivityItemList(this.activityItemParams).then(res => {
            if (res != undefined && res.data.data && res.data.data.total_count >0) {
              this.activityItemListsData = res.data.data.list
              this.activityItemTotalCount = res.data.data.total_count
            }
            this.ItemLoading = false
          })
        }
      },
      handleGoodsCurrentChange(page_num){
        this.ItemLoading = true
        this.activityItemDialog = true
        this.activityItemParams.page = page_num
        getMarketingActivityItemList(this.activityItemParams).then(res => {
          if (res != undefined && res.data.data && res.data.data.total_count > 0) {
            this.activityItemListsData = res.data.data.list
            this.activityItemTotalCount = res.data.data.total_count
          }
          this.ItemLoading = false
        })
      },
      handleGoodsSizeChange (pageSize) {
        this.ItemLoading = true
        this.activityItemDialog = true
        this.activityItemParams.page = 1
        this.activityItemParams.pageSize = pageSize
        getMarketingActivityItemList(this.activityItemParams).then(res => {
          if (res != undefined && res.data.data && res.data.data.total_count > 0) {
            this.activityItemListsData = res.data.data.list
            this.activityItemTotalCount = res.data.data.total_count
          }
          this.ItemLoading = false
        })
      },
      handleCancel () {
        this.activityItemDialog = false
      },
      dateChange (val) {
        if (val.length > 0) {
          this.params.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.params.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        }
        this.params.page = 1
        this.getFullMinusLists()
      },
      handleCurrentChange (val) {
        this.params.page = val
        this.loading = false
        this.getFullMinusLists()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getFullMinusLists()
      },
      dateStrToTimeStamp (str) {
        return Date.parse(new Date(str))/1000
      },
      getFullMinusLists (isExport) {
        if (isExport === undefined) {
          isExport = false;
        }
        this.loading = true
        var filter = this.params
        if (isExport) {
          filter.is_export = true
        }
        getMarketingActivityList(filter).then(response => {
          if (!isExport) {
            this.list = response.data.data.list
            this.total_count = Number(response.data.data.total_count)
          }
          this.loading = false
        })
      },
      handleDealerCancel () {
        this.useDealerMarketing = false
      },
      showDealerCard () {
        this.fetchDealerParams = {
          marketing_type: 'full_minus',
          marketing_name: '',
          search_status: '',
          page: 1,
          pageSize: 10
        }
        this.useDealerMarketing = true
        this.searchList(false)
      },
      dealerSearch (val) {
        val && val.dealer_id
        this.fetchDealerParams.store_id = val.dealer_id
        this.fetchDealerParams.page = 1
        this.searchList(false)
      },
      searchList (isExport) {
        var filter = this.fetchDealerParams
        filter.use_shop = 2
        if (isExport) {
          filter.is_export = true
        }
        if (this.exportMarketing.length > 0) {
          filter.marketing_id = this.exportMarketing
        }
        getMarketingActivityList(filter).then(response => {
          if (!isExport) {
            this.dealerMarketingList = response.data.data.list
            this.dealer_pagers.total = Number(response.data.data.total_count)
          } else {
            this.$message({
              type: 'success',
              message: '已加入执行队列，请在设置-导出列表中下载'
            })
          }
        })
      },
      doSearche () {
        this.fetchDealerParams.page = 1
        this.searchList(false)
      },
      handleDealerChange (val) {
        this.fetchDealerParams.page = val
        this.searchList(false)
      },
      handleDealerSizeChange (val) {
        this.fetchDealerParams.pageSize = val
        this.searchList(false)
      },
      handleSelectionCard (val) {
        val.forEach(info => {
          this.exportMarketing.push(info.marketing_id)
        })
      },

      updateStatusCommunityAction (row) {
        var msg = '此操作将永久终止该活动, 是否继续?'
        this.$confirm(msg, '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              removeMarketingActivity({marketing_id: row.marketing_id, isEnd: true}).then(response => {
                this.getFullMinusLists()
                this.$message({
                  message: '修改活动状态成功',
                  type: 'success',
                  duration: 5 * 1000
                })
              })
            }
            done()
          }
        })
      },
      exportData () {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.getFullMinusLists(true)
        this.$message({
          type: 'success',
          message: '已加入执行队列，请在设置-导出列表中下载'
        })
        loading.close()
      },
    },
    mounted () {
      this.getFullMinusLists()
    }
  }
</script>
